import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FooterLinkConstants from "../../constants/FooterLinkConstants";

const MainBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background-color: white;

  @media (max-width: 800px) {
    // display: none;
    align-items: start;
    padding-left: 20px;
  }
`;

const LinkContainer = styled.div`
  width: 700px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  z-index: 40;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-top: 0px;
    // height: 184px;
    height: auto;
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  color: #344054;
  width: auto;
`;

const FooterLink = styled.div`
  padding: 0;
  margin: 0 10px;
  width: auto;
  color: #344054;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-top: 15px;
    // height: 184px;
    height: auto;
  }
`;

function FooterLinkBox(): JSX.Element {
  const { t } = useTranslation();
  // const [activeLink, setActiveLink] = useState<string>("home");
  const scrollToComponent = (id: any) => {
    const element = document.getElementById(`${id}Carousel`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start", // Scroll so that the top of the element is at the top of the viewport
        inline: "nearest", // Keep the element as close to the center horizontally as possible
      });
    } else {
      const ele2 = document.getElementById(`${element}Carousel`);
      ele2?.scrollIntoView({
        behavior: "smooth",
        block: "start", // Scroll so that the top of the element is at the top of the viewport
        inline: "nearest", // Keep the element as close to the center horizontally as possible
      });
    }
  };

  const onUpdateActiveLink = () => {
    // setActiveLink(value);
  }; // value is like home

  return (
    <MainBox>
      <img src="/footerLogo.svg" alt="logo" />
      <LinkContainer>
        {FooterLinkConstants.map((e) => (
          <FooterLink>
            {e.internal ? (
              <Anchor href={e.link} onClick={() => scrollToComponent(e.link)}>
                {t(`footer.footer_links.${e.name}`)}
              </Anchor>
            ) : (
              <Link
                to={e.link}
                target={e.target && e.target}
                style={{
                  textDecoration: "none",
                  color: "#344054",
                }}
                onClick={() => onUpdateActiveLink()}
              >
                {t(`footer.footer_links.${e.name}`)}
              </Link>
            )}
          </FooterLink>
        ))}
      </LinkContainer>
    </MainBox>
  );
}

export default FooterLinkBox;
