interface CountryItem {
  name: string;
  code: string;
  lang?: string;
  flag: string;
}

const CountryConstants: CountryItem[] = [
  { name: "Arabic", code: "ARE", lang: "ar", flag: "ar" },
  { name: "Bulgarian", code: "BGR", lang: "bgr", flag: "bgr" },
  { name: "Chinese (simplified)", code: "CHN", lang: "zhs", flag: "zhs" },
  { name: "Chinese (traditional)", code: "TWN", lang: "zht", flag: "zht" },
  { name: "Czech", code: "CZE", lang: "cs", flag: "cs" },
  { name: "Dutch", code: "SXM", lang: "nl", flag: "nl" },
  { name: "English", code: "GBR", lang: "en", flag: "en" },
  { name: "French", code: "FRA", lang: "fr", flag: "fr" },
  { name: "German", code: "DEU", lang: "de", flag: "de" },
  { name: "Hungarian", code: "HUN", lang: "hu", flag: "hu" },
  { name: "Indonesian", code: "IDN", lang: "id", flag: "id" },
  { name: "Italian", code: "ITA", lang: "it", flag: "it" },
  { name: "Japanese", code: "JPN", lang: "ja", flag: "ja" },
  { name: "Korean", code: "KOR", lang: "ko", flag: "ko" },
  { name: "Polish", code: "POL", lang: "pl", flag: "pl" },
  { name: "Portuguese", code: "PRT", lang: "pt", flag: "pt" },
  { name: "Romanian", code: "ROU", lang: "ro", flag: "ro" },
  { name: "Russian", code: "RUS", lang: "ru", flag: "ru" },
  { name: "Slovak", code: "SVK", lang: "sk", flag: "sk" },
  { name: "Spanish", code: "ESP", lang: "es", flag: "es" },
  { name: "Thai", code: "THA", lang: "th", flag: "th" },
  { name: "Turkish", code: "TUR", lang: "tr", flag: "tr" },
  { name: "Vietnamese", code: "VNM", lang: "vi", flag: "vi" },
];

export default CountryConstants;
