import React, { ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LearnMoreBtn from "./LearnMoreBtn";

const MainBox = styled.div`
  margin-top: 100px;
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  z-index: 10;
  @media (max-width: 800px) {
    margin-top: 80px;
  }
  @media (max-width: 700px) {
    margin-bottom: 50px;
  }
`;

const InternalBox = styled.div`
  position: sticky;
  width: 1440px;
  height: auto;
  min-height: 600px;
  border-radius: 40px;
  background-color: #dfd1f4;
  background-image: url(${"/assets/background.svg"});
  background-position: left bottom 10px;
  background-size: 40% auto;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 1440px) {
    width: 1216px;
  }
  @media (max-width: 1100px) {
    width: 98%;
    min-height: 500px;
  }
  @media (max-width: 800px) {
    width: 95%;
    height: auto;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${""});
    // padding-bottom: 20px;
  }

  @media (max-width: 520px) {
    height: auto;
    width: 90%;
    padding: 0;
    padding-bottom: 20px;
    margin: 0;
  }

  @media (max-width: 475px) {
    width: 335px;
    height: auto;
  }
`;

const ContentBox = styled.div`
  width: 411px;
  height: auto;
  margin-top: 100px;
  // padding-bottom: 100px;
  margin-left: 70px;
  @media (max-width: 800px) {
    width: 90%;
    // margin-top: 50px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  @media (max-width: 520px) {
    padding: 0;
    margin: 0;
    margin-top: 30px;
  }
`;

const Title = styled.div`
  width: 520px;
  height: auto;
  font-weight: 600;
  font-size: 50px;
  line-height: 70px;
  height: auto;
  @media (max-width: 1440px) {
    width: 440px;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
  }
  @media (max-width: 800px) {
    height: auto;
    width: 380px;
    text-align: center;
    padding: 0;
  }

  @media (max-width: 520px) {
    margin-top: 0px;
    font-size: 25px;
    line-height: 35px;
    height: auto;
    padding: 0 0px;
    text-align: center;
    width: 320px;
  }
`;

const Content = styled.div`
  width: 411px;
  height: auto;
  margin-top: 20px;

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 20px;
    padding: 0 20px;
    text-align: center;
    height: auto;
  }

  @media (max-width: 515px) {
    text-align: center;
    height: auto;
  }
`;

const ContentSec = styled.div`
  width: 411px;
  height: auto;
  margin-top: 20px;

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
    margin-top: 20px;
    padding: 0 20px;
    text-align: center;
  }

  @media (max-width: 500px) {
    text-align: center;
  }
`;

const BtnContainer = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    display: none;
    justify-content: center;
    align-items: center;
  }
`;

const BtnSmallContainer = styled.div`
  width: 100%;
  display: none;
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledImageContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 60%;
  min-height: 600px;
  z-index: 1;
  @media (max-width: 1100px) {
    width: 45%;
    min-height: 500px;
  }
  @media (max-width: 800px) {
    display: none;
  }

  & img {
    position: absolute;
    width: 300px;
    height: 300px;

    @media (max-width: 1100px) {
      width: 200px;
      height: 200px;
    }
  }

  & .buy {
    bottom: 0;
    right: 160px;
    z-index: 1;

    @media (max-width: 1100px) {
      // bottom: 80px;
      right: 100px;
    }
  }

  & .send {
    bottom: 120px;
    right: 60px;
    z-index: 5;

    @media (max-width: 1100px) {
      bottom: 80px;
      right: 40px;
    }
  }

  & .receive {
    bottom: 220px;
    right: 0px;
    z-index: 5;
    @media (max-width: 1100px) {
      bottom: 140px;
      right: 0px;
    }
  }
`;

const StyledOneImgContainer = styled.div`
  z-index: 1;
  display: none;
  margin-top: 40px;
  @media (max-width: 800px) {
    display: block;
  }
`;

function WhereToStoreCrypto(): ReactElement {
  const { t } = useTranslation();
  return (
    <MainBox>
      <InternalBox>
        <ContentBox>
          <Title>{t("card_component.card_component_title")}</Title>
          <Content>{t("card_component.card_component_content")}</Content>
          <ContentSec>
            {t("card_component.card_component_second_content")}
          </ContentSec>
          <BtnContainer>
            <LearnMoreBtn />
          </BtnContainer>
        </ContentBox>

        <StyledImageContainer>
          <img src="/rotated/buy.svg" alt="alt" className="buy" />
          <img src="/rotated/send.svg" alt="alt" className="send" />
          <img src="/rotated/receive.svg" alt="alt" className="receive" />
        </StyledImageContainer>
        <StyledOneImgContainer>
          <img
            src="/rotated.png"
            style={{
              height: "230px",
              width: "330px",
            }}
            alt="alt"
            // className="buy"
          />
        </StyledOneImgContainer>
        <BtnSmallContainer>
          <LearnMoreBtn />
        </BtnSmallContainer>
      </InternalBox>
    </MainBox>
  );
}

export default WhereToStoreCrypto;
