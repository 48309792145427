import React, { ReactElement, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type ContainerProps = {
  submitted: boolean;
};

// Define your styled component
const StyledInputBox = styled.div<ContainerProps>`
  width: auto;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 100px;
  background-color: white;
  // font-family: "SF Pro Display, sans-serif";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  // letter-spacing: 0.03em;
  text-align: left;
  padding: 0px;

  @media (max-width: 750px) {
    width: auto;
    min-width: 330px;
    min-width: ${(props) => (props.submitted ? "auto" : "330px")};
    min-height: ${(props) => (props.submitted ? "auto" : "48px")};
    margin-bottom: 20px;
  }

  input {
    border: 0;
    outline: none;
    width: 320px;
    padding: 0;
    color: blue;

    @media (max-width: 750px) {
      width: 286px;
    }
  }

  div {
    text-align: center;
    @media (max-width: 750px) {
      max-width: 300px;
      width: 100%;
      height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const SubmittedText = styled.div`
  width: auto;
  min-width: 400px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 100px;
  background-color: #12b76a;
  color: white;
  // font-family: "SF Pro Display, sans-serif";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: left;
  padding: 0 20px;
  overflow-y: hidden;
  @media (max-width: 800px) {
    min-width: 300px;
    width: max-content;
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }
`;

function JoinWaitlistInputBox(props: any): ReactElement {
  const { t } = useTranslation();
  const {
    setInput,
    err,
    setErr,
    submitted,
    handleInputFocus,
    handleInputBlur,
  } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <StyledInputBox
      submitted={submitted}
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus();
          handleInputFocus();
        }
      }}
    >
      {!submitted ? (
        <>
          <input
            ref={inputRef}
            onChange={(e) => {
              setInput(e.target.value);
              setErr(false);
            }}
            onBlur={handleInputBlur}
            style={{ color: err ? "red" : "blue", margin: "0 20px" }}
          />
          {!err ? (
            <div
              style={{
                color: "#98a2b3",
                margin: "0 20px",
                // width: "auto",
                overflow: "hidden",
              }}
            >
              {t(`join_wait_list.enter_your_email`)}
            </div>
          ) : (
            <div style={{ color: "red", margin: "0 20px" }}>
              {t(`join_wait_list.error_text`)}
            </div>
          )}
        </>
      ) : (
        <SubmittedText>{t(`join_wait_list.success_text`)}</SubmittedText>
      )}
    </StyledInputBox>
  );
}

export default JoinWaitlistInputBox;
