import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FrameContent from "./FrameContent";

interface ImageObject {
  img: string;
  content: any;
}

const FrameCardWrapper = styled.div`
  width: 383px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @media (max-width: 1200px) {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
  }
`;

interface FrameCardProps {
  imageObj: ImageObject;
}

function FrameCard({ imageObj }: FrameCardProps): JSX.Element {
  const { t } = useTranslation();
  const { img, content } = imageObj;
  return (
    <FrameCardWrapper>
      <img src={img} alt="url" style={{ width: "100%" }} />
      <FrameContent content={t(`top_carousel.${content}`)} />
    </FrameCardWrapper>
  );
}

export default FrameCard;
