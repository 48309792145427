// LotteAnimation.tsx//
import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.css";
import lottie from "lottie-web";
import lotteAnimation from "./cardMotionAnimation.json";

const MainContainer = styled.div`
  width: 700px;
  height: 550px;
  display: flex;
  justify-content: start;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // overflow: hidden;
  // border: 1px solid red;
  // position: "relative";
  // @media (min-width: 1500px) {
  //   height: 1000px;
  // }
  @media (max-width: 1250px) {
    display: none;
  }
  // border: 1px solid red;
`;

// const ChildContainer = styled.div`
//   // width: 1250px;
//   // height: auto;
//   // overflow: hidden;
//   border: 2px solid blue;
// `;

const LotteAnimationBox = styled.div`
  background-repeat: no-repeat;
  overflow: hidden;
  background-color: transparent;
  width: inherit;
  height: inherit;
  // border: 5px solid yellow;
  // position: relative;
  top: 80px;
  left: 250px;
`;

function LotteAnimation(): ReactElement {
  useEffect(() => {
    const elem = document.querySelector("#lotte-animation");
    if (elem) {
      lottie.loadAnimation({
        container: elem,
        animationData: lotteAnimation,
        renderer: "svg", // You can specify the renderer type if needed
        loop: true, // Set to true if you want the animation to loop
        autoplay: true, // Set to true if you want the animation to play automatically
      });
    }
  }, []);

  return (
    <MainContainer>
      {/* <ChildContainer> */}
      {/* <div
          id="lotte-animation"
          style={{
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
            backgroundColor: "transparent",
            width: "100px",
            height: "70px",
            border: "5px solid yellow",
            position: "absolute",
            top: "80px",
            left: "250px",
          }}
        /> */}
      <LotteAnimationBox id="lotte-animation" />
      {/* </ChildContainer> */}
    </MainContainer>
  );
}

export default LotteAnimation;
