import styled from "styled-components";
// import { useEffect, useRef } from "react";
import FrameCard from "./FrameCard";
import frameCardConstants from "../../constants/FrameCardConstants";
// import isVisible from "../../utils/viewChecker";

interface ImageObject {
  img: string;
  content: string;
}

const MainBox = styled.div`
  width: 100vw;
  height: auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1200px) {
    height: auto;
  }
  @media (max-width: 800px) {
    margin-top: 80px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const CardBox = styled.div`
  width: 1440px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1440px) {
    width: 1216px;
  }
  @media (max-width: 1200px) {
    width: 750px;
  }
`;

function FrameGroup(): JSX.Element {
  return (
    <>
      <div id="features" />
      <MainBox>
        <CardBox>
          {frameCardConstants.map((e: ImageObject) => (
            <FrameCard imageObj={e} key={e.content} />
          ))}
        </CardBox>
      </MainBox>
    </>
  );
}

export default FrameGroup;
