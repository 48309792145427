interface NavItem {
  name: string;
  link: string;
  val: string;
  target?: string;
  internal?: boolean;
}

const FooterLinkConstants: NavItem[] = [
  {
    name: "footer_features",
    link: "#features",
    val: "features",
    internal: true,
  },
  {
    name: "how_to_get_it",
    link: "#howitworks",
    val: "howitworks",
    internal: true,
  },
  {
    name: "terms",
    link: "/terms-and-conditions",
    val: "terms",
    target: "_blank",
    internal: true,
  },
  // {
  //   name: "privacy",
  //   link: "/privacy",
  //   val: "privacy",
  //   target: "_blank",
  // },
  {
    name: "cookies",
    link: "/cookies",
    val: "cookies",
    target: "_blank",
    internal: true,
  },
];

export default FooterLinkConstants;
