import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FooterConstants from "../../constants/FooterConstants";

const MainBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  background-color: white;
  margin-top: 32px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #98a2b3;

  @media (max-width: 700px) {
    padding: 0;
    height: auto;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;
    // padding-left: 20px;
    margin-bottom: 10px;
  }
`;

const InternalContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eaecf0;
  padding-top: 32px;
  @media (max-width: 1440px) {
    max-width: 1216px;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: 0 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    // margin: 0 30px;
    height: auto;
    overflow: hidden;
  }
`;

const NestedBox = styled.div`
  max-width: 614px;
  height: auto;
  order: 1;

  @media (max-width: 800px) {
    // overflow: hidden;
    margin-top: 10px;
    order: 2;
  }
`;

const IconsContainer = styled.div`
  width: 150px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-itmes: center;
  order: 2;
  z-index: 90;
  @media (max-width: 800px) {
    z-index: 40;
    order: 1;
    width: 180px;
    height: 50px;
  }
`;

const SocialIconContainer = styled.div`
  text-decoration: none;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 800px) {
    margin-top: 15px;
  }
  @media (max-width: 750px) {
    margin-top: 15px;
    width: 32px;
    height: 32px;
  }
`;

const SocialIcon = styled.img`
  height: 12px;
  width: 12px;
  overflow: hidden;

  @media (max-width: 750px) {
    width: 15px;
    height: 15px;
  }
`;

function FooterBottomBox(): JSX.Element {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <MainBox>
      <InternalContainer>
        <NestedBox>
          <div data-testid="best-card">
            {t("footer.bottom_container.bottom_container_top_text")
              .split("2023")
              .join(`${currentYear}`)}
          </div>
          <div style={{ marginTop: "15px" }}>
            {t("footer.bottom_container.bottom_container_bottom_text")}
          </div>
        </NestedBox>
        <IconsContainer>
          {FooterConstants.map((e) => (
            <Link
              to={`${e.redirect}`}
              target="_blank"
              style={{ textDecoration: "none", cursor: "pointer" }}
              key={e.link}
            >
              <SocialIconContainer>
                <SocialIcon
                  src={e.link}
                  alt="icons"
                  style={
                    e.name === "insta" ? { height: "30px", width: "30px" } : {}
                  }
                />
              </SocialIconContainer>
            </Link>
          ))}
        </IconsContainer>
      </InternalContainer>
    </MainBox>
  );
}

export default FooterBottomBox;
