import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Navbar from "./components/NavBar/NavBar";
import LandingPage from "./pages/LandingPage";
import CountryConstants from "./constants/CountryListConstants";
import "./App.css";
import NavConstants from "./constants/NavConstants";
import Cookies from "./pages/Cookies";
import TermsAndConditions from "./pages/TermsAndConditions";
import validLanguages from "./utils/LanguageConstants";
import urlReplacer from "./utils/UrlReplacer";

const AppContainer = styled.div`
  overflow: hidden;
  position: relative;
  font-family: "Poppins", sans-serif;
`;

const FlagImage = styled.div<{ flag: string }>`
  width: ${(props) =>
    props.flag === "zhs" || props.flag === "zht" ? "32px" : "24px"};
  height: ${(props) =>
    props.flag === "zhs" || props.flag === "zht" ? "24px" : "24px"};
  background-image: url(${(props) => `/flags/${props.flag}.svg`});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 5px;
`;

const DropDownParent = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 64px;
  background-color: transparent;
  z-index: 91;
`;

const DropdownContainer = styled.div`
  position: fixed;
  width: 185px;
  height: 250px;
  border-radius: 8px;
  right: 40px;
  top: 64pxpx;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 50;
  // backdrop-filter: blur(5px);
  background-color: white;
  // box-shadow: 1px 2px 160px 14px rgba(90, 99, 255, 1);
  // -webkit-box-shadow: 1px 2px 160px 14px rgba(90, 99, 255, 1);
  // -moz-box-shadow: 1px 2px 160px 14px rgba(90, 99, 255, 1);

  @media (min-width: 600px) {
    right: 50px;
  }

  @media (min-width: 700px) {
    right: 110px;
  }

  @media (min-width: 1000px) {
    right: 110px;
  }

  @media (min-width: 1280px) {
    right: 150px;
  }

  @media (min-width: 1350px) {
    right: 180px;
  }

  @media (min-width: 1440px) {
    right: 240px;
  }

  @media (min-width: 1680px) {
    right: 330px;
  }

  @media (min-width: 1780px) {
    right: 380px;
  }

  @media (min-width: 1920px) {
    right: 470px;
  }

  @media (min-width: 2048px) {
    right: 520px;
  }

  @media (min-width: 2200px) {
    right: 620px;
  }

  @media (min-width: 2560px) {
    right: 780px;
  }
`;

const SmallNavDropdown = styled.div<{ navOpen: boolean }>`
  position: fixed;
  width: 100vw;
  height: 176px;
  left: 0px;
  top: 64px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 80;
  background-color: white;
  display: none;
  @media (max-width: 835px) {
    display: ${(props) => (props.navOpen ? "block" : "none")};
  }
`;

const CountryItem = styled.div`
  display: flex;
  justify-content: start;
  width: 174px;
  height: auto;
  align-items: center;
  padding-left: 10px;
  overflow-x: hidden;
  cursor: pointer;

  &:hover {
    background-color: lightgray; /* Add your desired background color here */
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  color: black;
`;

const CountryName = styled.div`
  margin-left: 10px;
  padding: 8px 0;
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  overflow-x: hidden;
`;

function App() {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(i18n.language);
  const [navOpen, setNavOpen] = useState(false);
  const [activeLink, setActiveLink] = useState<string>("home");
  const { t } = useTranslation();
  const { location } = window;
  useEffect(() => {
    // console.log(location.href, "href");
    const pathArr = location.pathname.split("/");
    if (validLanguages.includes(pathArr[pathArr.length - 1])) {
      i18n?.changeLanguage(pathArr[pathArr.length - 1]);
      setSelectedLanguage(pathArr[pathArr.length - 1]);
    }
  }, [location]);
  const scrollToComponent = (id: any) => {
    const element = document.getElementById(`${id}Carousel`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start", // Scroll so that the top of the element is at the top of the viewport
        inline: "nearest", // Keep the element as close to the center horizontally as possible
      });
    } else {
      const ele2 = document.getElementById(`${element}`);
      ele2?.scrollIntoView({
        behavior: "smooth",
        block: "start", // Scroll so that the top of the element is at the top of the viewport
        inline: "nearest", // Keep the element as close to the center horizontally as possible
      });
    }
  };

  const onUpdateActiveLink = (value: string) => {
    setActiveLink(value);
  }; // value is like home

  return (
    <Router>
      <AppContainer>
        <Navbar
          {...{
            selectedLanguage,
            dropDownOpen,
            setDropDownOpen,
            navOpen,
            setNavOpen,
          }}
        />
        <DropDownParent
          style={{
            display: dropDownOpen ? "block" : "none",
          }}
          onClick={() => {
            setDropDownOpen(!dropDownOpen);
          }}
        >
          <DropdownContainer>
            {CountryConstants.map((e) => (
              <CountryItem
                onClick={() => {
                  setSelectedLanguage(e.lang);
                  // setDropDownOpen(false);
                  i18n.changeLanguage(e.lang);
                  urlReplacer(e.lang || "");
                }}
              >
                <FlagImage flag={e.flag} />
                <CountryName>{e.name}</CountryName>
              </CountryItem>
            ))}
          </DropdownContainer>
        </DropDownParent>
        <SmallNavDropdown navOpen={navOpen}>
          {NavConstants.map((e) => (
            <div
              data-testid="best-card"
              style={{
                width: "100%",
                height: "48px",
                padding: "12px 16px",
                margin: "0px 0px 8px 0px",
              }}
              key={e.link}
              onClick={() => setNavOpen(false)}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  setNavOpen(false);
                }
              }}
              role="button" // Add role="button" to indicate it's clickable
              tabIndex={0}
            >
              {e.internal ? (
                <Anchor
                  href={e.link}
                  onClick={() => {
                    scrollToComponent("myComponent");
                    window.location.href = `/${e.link}`;
                  }}
                >
                  {t(`nav_bar.nav_bar_links.${e.name}`)}
                </Anchor>
              ) : (
                <Link
                  to={e.link}
                  target={e.target && e.target}
                  style={{
                    textDecoration: "none",
                    color: activeLink === e.val ? "gray" : "black",
                  }}
                  onClick={() => onUpdateActiveLink("home")}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                      onUpdateActiveLink("home");
                    }
                  }}
                  role="button" // Add role="button" to indicate it's clickable
                  tabIndex={0}
                >
                  {t(`nav_bar.nav_bar_links.${e.name}`)}
                </Link>
              )}
            </div>
          ))}
        </SmallNavDropdown>
        {/* <Routes>
          <Route path="/*" element={<LandingPage />} />
          <Route path="/cookies/*" element={<Cookies />} />
          <Route
            path="/terms-and-conditions/*"
            element={<TermsAndConditions />}
          />
        </Routes> */}
        <Routes>
          {validLanguages.map((lang) => (
            <Route key={lang} path={`/${lang}`} element={<LandingPage />} />
          ))}
          {validLanguages.map((lang) => (
            <Route key={lang} path={`/cookies/${lang}`} element={<Cookies />} />
          ))}
          {validLanguages.map((lang) => (
            <Route
              key={lang}
              path={`/terms-and-conditions/${lang}`}
              element={<TermsAndConditions />}
            />
          ))}
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="">
      <App />
    </Suspense>
  );
}
