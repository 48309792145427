import React, { ReactElement } from "react";
import styled from "styled-components";

interface TextProps {
  title: string;
  content?: string;
  secondContent?: string;
}

interface MajorContentProps {
  textProps: TextProps;
}

const MainBox = styled.div`
  width: 660px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow: hidden;
  font-family: "Poppins", "Barlow", "sans-serif";
  @media (max-width: 1440px) {
    width: 520px;
  }
  @media (max-width: 1200px) {
    width: auto;
  }

  @media (max-width: 750px) {
    height: auto;
    width: 335px;
    align-items: center;
    word-wrap: break-word;
    margin-top: 40px;
  }
`;

const TitleText = styled.div`
  padding-top: 40px;
  font-size: 50px;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0.03em;
  text-align: left;
  overflow: hidden;
  width: 660px;
  @media (max-width: 1440px) {
    font-size: 36px;
    line-height: 50px;
    width: 500px;
  }
  @media (max-width: 1200px) {
    width: 300px;
    line-height: 43px;
  }
  @media (max-width: 750px) {
    width: 300px;
    padding-top: 0px;
    text-align: center;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0em;
  }
`;

const ContentText = styled.div`
  padding-top: 16px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;
  overflow: hidden;
  width: 660px;
  @media (max-width: 1440px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    width: 520px;
  }
  @media (max-width: 1210px) {
    width: 400px;
  }
  @media (max-width: 750px) {
    text-align: center;
    width: 335px;
  }
`;

function MajorContent({ textProps }: MajorContentProps): ReactElement {
  const { title, content, secondContent } = textProps;

  return (
    <MainBox>
      <TitleText>{title}</TitleText>
      <ContentText>{content && content}</ContentText>
      <ContentText>{secondContent && secondContent}</ContentText>
    </MainBox>
  );
}

export default MajorContent;
