import styled from "styled-components";
import { useEffect } from "react";

import HeroSection from "../components/HeroSection/HeroSection";
import FrameGroup from "../components/FrameGroup/FrameGroup";
import HowToUseBestCard from "../components/HowToUseBestCard/HowToUseBestCard";
import MajorContent from "../components/MajorContentBox/MajorContent";
import WhereToStoreCrypto from "../components/WhereToStoreCrypto/WhereToStoreCrypto";
import Footer from "../components/Footer/Footer";
import FAQ from "../components/FAQ/FAQ";
import FrameGroupCarousal from "../components/FrameGroupCarousal/FrameGroupCarousal";
import HowToUseBestCardCarousal from "../components/HowToUseBestCardCarousal/HowToUseBestCardCarousal";

const PageContainer = styled.div`
  width: 100vw;
  margin-top: 0px;
  z-index: 1;
  background-color: #f7f8fa;
  overflow-x: hidden;
`;

const HeroContainer = styled.div`
  height: 100vh;
  @media (max-width: 1240px) {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
// type LandingPageProps = {
//   selectedLanguage: string;
// };
// const StyledShadow = styled.div<LandingPageProps>`
function LandingPage() {
  useEffect(() => {
    document.title = "Best Card Spend your crypto.  Anytime. Anywhere."; // Change the title here
  }, []);

  return (
    <PageContainer>
      <HeroContainer>
        <HeroSection />
      </HeroContainer>
      <FrameGroup />
      <FrameGroupCarousal />
      <MajorContent />
      <HowToUseBestCard />
      <HowToUseBestCardCarousal />
      <WhereToStoreCrypto />
      <FAQ />
      <Footer />
    </PageContainer>
  );
}

export default LandingPage;
