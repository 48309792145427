import styled from "styled-components";
import FooterLinkBox from "./FooterLinkBox";
import FooterBottomBox from "./FooterBottomBox";

const MainBox = styled.div`
  width: 100vw;
  height: auto;
  padding: 40px 0;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  @media (max-width: 800px) {
    margin-top: 80px;
  }
`;

const CardBox = styled.div`
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: space-between;
  @media (max-width: 1440px) {
    max-width: 1216px;
  }
`;

function Footer(): JSX.Element {
  return (
    <MainBox>
      <CardBox>
        <FooterLinkBox />

        <FooterBottomBox />
      </CardBox>
    </MainBox>
  );
}

export default Footer;
