import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";
import HowToUseBestCardConstants from "../../constants/HowToUseBestCardConstants";

const MainBox = styled.div`
  width: 100vw;
  height: auto;
  min-height: 440px;
  margin-top: 100px;
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1200px) {
    display: flex;
    margin-top: 0px;
  }
`;

const CardBox = styled.div`
  width: 335px;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
`;

const FrameCard = styled.div`
  height: 360px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
`;

const ImgContainer = styled.div`
  width: 225px;
  height: 150px;
  margin: 32px 0;
  background-color: #f7f8fa;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NumberDiv = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 4px solid #f1f2ff;
  background-color: #a0acd4;
  font-family: poppins;
  font-weight: 500;
  font-size: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 60px;
`;

const CircleContainer = styled.div`
  width: 70px;
  display: flex;
  justify-content: space-between;
`;

const translatedText: string[] = [
  "download_best_wallet_app",
  "fund_your_wallet_with_crypto",
  "spend_your_crypto_anywhere",
  "earn_cashback_on_every_transaction",
];

function CircleBox(props: any): JSX.Element {
  const { currSlide, setCurrSlide, HowToUseBestCardConstants } = props;

  return (
    <CircleWrapper>
      <CircleContainer>
        {HowToUseBestCardConstants.map((e: any, i: any) => (
          <div
            style={{
              width: "13px",
              height: "13px",
              borderRadius: "100%",
              border: "1px solid #CECECE",
              backgroundColor: currSlide === i ? "#5A63FF" : "transparent",
              cursor: "pointer",
            }}
            role="button" // Add role="button" to indicate it's an interactive element
            tabIndex={0}
            aria-label={`Slide ${i + 1}`} // Add a descriptive label
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                setCurrSlide(i);
              }
            }}
            onClick={() => {
              setCurrSlide(i);
            }}
          />
        ))}
      </CircleContainer>
    </CircleWrapper>
  );
}

function HowToUseBestCardCarousal(): JSX.Element {
  const [currSlide, setCurrSlide] = useState(0);
  const { t } = useTranslation();
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currSlide < HowToUseBestCardConstants.length - 1) {
        setCurrSlide(currSlide + 1);
      } else {
        setCurrSlide(0);
      }
    },
    onSwipedRight: () => {
      if (currSlide > 0) {
        setCurrSlide(currSlide - 1);
      } else {
        setCurrSlide(HowToUseBestCardConstants.length - 1);
      }
    },
  });
  return (
    <MainBox id="howitworksCarousel" {...handlers}>
      <CardBox>
        <FrameCard
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            height: "auto",
          }}
        >
          <ImgContainer>
            <img
              src={HowToUseBestCardConstants[currSlide].img}
              style={{ width: "auto", height: "auto" }}
              alt="carousalImg"
            />
          </ImgContainer>
          <NumberDiv>{currSlide + 1}</NumberDiv>

          <div
            style={{
              width: "200px",
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "400",
              padding: "0 25px",
              margin: "20px 0 40px 0",
            }}
          >
            {t(
              // eslint-disable-next-line prettier/prettier
              `bottom_carousel.bottom_carousel_contents.${translatedText[currSlide]}`
            )}
          </div>
        </FrameCard>
        <CircleBox
          {...{ currSlide, setCurrSlide, HowToUseBestCardConstants }}
        />
        {/* ))} */}
      </CardBox>
    </MainBox>
  );
}

export default HowToUseBestCardCarousal;
