import React from "react";
import styled from "styled-components";
import ContentBox from "./ContentBox";

interface ImageProps {
  img: string;
}

interface BestCardProps {
  imageProps: ImageProps;
  ind: number;
  text: string;
}

const BestCardWrapper = styled.div`
  width: 336px;
  height: 400px;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 32px 32px;
  border-radius: 20px;
  background-color: white;
  @media (max-width: 1440px) {
    width: 280px;
  }
  @media (max-width: 1200px) {
    width: 200px;
    // height: 100%;
    height: 400px;
    padding: 0px;
    // background-color: #f7f8fa;
    margin: 0 5px;
  }

  @media (max-width: 800px) {
    width: 150px;
    height: auto;
    padding: 0px;
    background-color: #f7f8fa;
  }
`;

const CenterImg = styled.div`
  width: 225px;
  height: 150px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;
  @media (max-width: 800px) {
    width: 150px;
    height: auto;
    padding: 0px;
    // background-color: #f7f8fa;
  }
`;

function BestCard({ imageProps, ind, text }: BestCardProps): JSX.Element {
  const { img } = imageProps;

  return (
    <BestCardWrapper>
      <CenterImg>
        <img src={img} alt="howtoImg" />
      </CenterImg>
      <ContentBox ind={ind} text={text} />
    </BestCardWrapper>
  );
}

export default BestCard;
