import React, { ReactElement } from "react";
import styled from "styled-components";

interface MajorContentProps {
  img: string;
  smallImg?: string;
}

const MainBox = styled.div`
  width: 592px;
  height: 539px;
  overflow: hidden;
  @media (max-width: 1200px) {
    width: 383px;
    height: 330px;
  }

  @media (max-width: 800px) {
    width: 300px;
    height: 300px;
  }
  @media (max-width: 500px) {
    width: 330px;
    height: 300px;
  }
`;

const WideImg = styled.img`
  width: 100%;
  overflow: hidden;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const SmallImg = styled.img`
  width: 100%;
  display: none;
  overflow: hidden;
  @media (max-width: 1200px) {
    display: block;
    width: 400px;
    height: 400px;
  }
  @media (max-width: 800px) {
    display: block;
    width: 330px;
    height: 300px;
  }
`;

function MajorContent(props: MajorContentProps): ReactElement {
  const { img, smallImg } = props;
  return (
    <MainBox>
      <WideImg src={img} style={{ width: "100%" }} alt="Content" />
      <SmallImg src={smallImg} alt="imgContent" />
    </MainBox>
  );
}

export default MajorContent;
