import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeroSectionContent from "./HeroSectionContent";
import ResponsiveCarousel from "../Carousel/Carousel";
import TopBottomBox from "../TopBottomBox/TopBottomBox";
import LotteAnimation from "../LotteAnimation/LotteAnimation";
// import LotteAnimation from "../LotteAnimation/LotteAnimation";

const HeroContainer = styled.div`
  width: 100vw;
  height: 99vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  z-index: 60;
  background-color: #f7f8fa;
  // transform: rotate(45deg); /* Adjust the rotation as needed */
  @media (max-width: 1250px) {
    // padding-top: 100px;
    align-items: center;
    justify-content: space-between;
  }
  // @media (max-height: 901px) {
  // padding-top: 70px;
  // }
  @media (min-height: 500px) and (max-width: 1000px) {
    padding-top: 80px;
  }
  @media (min-width: 700px) and (max-width: 1250px) {
    flex-direction: row;
    padding-top: 0px;
  }
`;

const HeroParent = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  // padding-top: 100px;
  width: 1440px;
  overflow: hidden;
  background-color: #f7f8fa;
  height: 80vh;
  @media (min-width: 700px) and (max-width: 1250px) {
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    padding-top: 0px;
    height: auto;
  }
  @media (max-width: 1440px) {
    max-width: 1216px;
  }

  @media (max-width: 700px) {
    padding-top: 0px;
    width: 100vw;
    margin-top: 0px;
    padding-top: 0px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
  @media (min-width: 1650px) {
    align-items: center;
  }
`;

const StyledImgContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  @media (max-height: 700px) {
    right: 1800px;
  }
`;

const StyledImg = styled.div<{ bg: string }>`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  background-image: url(${(props) => `${props.bg}`});
  background-size: cover;
  background-position: center;
  @media (max-width: 700px) {
    display: none;
  }
`;

const AnimationContainer = styled.div`
  // z-index: 10;
  // position: absolute;
  // top: 20px;
  // height: 100vh;
  // max-height: 700px;
  // width: 100vw;
  // overflow: hidden;
  z-index: 10;
  position: absolute;
  // top: 50px;
  left: 0px;
  width: 100vw;
  padding-left: 550px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // border: 1px solid black;
  @media (min-width: 1650px) {
    // height: 90vh;
    // top: 150px;
    // align-items: center;
    padding-left: 700px;
  }
`;

type StyledContainerProps = {
  isAndroid: boolean;
};

const ImageContainer = styled.div<StyledContainerProps>`
  margin-top: 0px;
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 10;
  display: none;
  overflow: hidden;
  margin-top: ${(props) => (props.isAndroid ? "30px" : "10px")};
  @media (min-width: 700px) and (max-width: 1250px) {
    margin-top: 0px;
  }
  @media (max-width: 1250px) {
    position: static;
    display: block;
  }
  @media (max-height: 800px && max-width: 500px) {
    disply: flex;
    justify-content: start;
    align-items: start;
    height: auto;
    width: auto;
    padding: 0px;
    margin-top: ${(props) => (props.isAndroid ? "40px" : "0px")};
  }
`;

const StyledSvg = styled.svg`
  width: 335px;
  height: 314px;
  overflow: hidden;
  @media (max-width: 1250px) {
    width: 335px;
    height: 277px;
  }

  @media (max-height: 800px) {
    height: 40vh;
    width: 80vw;
    margin-top: 10px;
  }
  @media (max-height: 575px) {
    height: 25vh;
  }
`;

const StyledSmallerImg = styled.image`
  // Your image styling here
  width: 100%;
  height: 100%;

  @media (max-height: 800px) {
    height: 35vh;
    width: 80vw;
  }

  @media (max-height: 575px) {
    height: 25vh;
  }
`;

// const BaseImg = styled.svg`
//   position: absolute;
//   width: 100%;
//   height: auto;
//   overflow: hidden;
//   z-index: 70;
//   top: 460px;
//   @media (max-width: 1250px) {
//     display: none;
//   }
// `;

// const StyledImage = styled.img`
//   height: 500px;
//   width: 520px; /* Set width to 'auto' by default */
//   @media (min-width: 1921px) {
//     width: 600px; /* Set width to 800px if screen width is more than 1650px */
//     height: 600px;
//   }
//   @media (max-width: 1250px) {
//     display: none;
//   }
// `;

const SmallImg = styled.div<{ bg: string }>`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  background-image: url(${(props) => `${props.bg}`});
  background-size: cover;
  background-position: center;
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
`;

function HeroSection(): JSX.Element {
  const [isAndroid, setIsAndroid] = useState(false);
  useEffect(() => {
    // Check if the user agent contains "Android" to identify Android devices
    if (/Android/i.test(navigator.userAgent)) {
      setIsAndroid(true);
    }
  }, []);

  return (
    <HeroContainer id="hero">
      <AnimationContainer>
        <LotteAnimation />
        {/* <img src="/newCardPic.svg" alt="newCard" style={{ width: "800px" }} /> */}
        {/* <StyledImage src="/newCardPic.svg" alt="images" /> */}
      </AnimationContainer>
      {/* <BaseImg>
        <image
          href="animationBase.svg"
          style={{ zIndex: "90px", width: "100vw" }}
        />
      </BaseImg> */}
      <StyledImgContainer>
        <StyledImg bg="/assets/headerBg.svg" />
        <SmallImg bg="/smallScreenBg.svg" />
      </StyledImgContainer>
      <HeroParent>
        <HeroSectionContent />
        <ImageContainer isAndroid={isAndroid}>
          <StyledSvg>
            <StyledSmallerImg xlinkHref="assets/smallCards.png" />
          </StyledSvg>
        </ImageContainer>
      </HeroParent>
      <TopBottomBox />
      <ResponsiveCarousel />
    </HeroContainer>
  );
}

export default HeroSection;
