import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer/Footer";

const HeaderTitle = styled.div`
  text-align: start;
  font-size: 48px;
  line-height: 1.08349;
  font-weight: 600;
  letter-spacing: -0.003em;
  margin: 100px 0 20px;
  @media only screen and (max-width: 600px) {
    font-size: 32px;
  }
`;

const ParaHead = styled.div`
  color: #000;
  font-size: 20px;
  padding-bottom: 20px;
`;

const ContentPara = styled.p`
  color: #000;
  font-size: 20px;
`;
function Cookies() {
  return (
    <>
      <div className="container" style={{ maxWidth: "1024px" }}>
        <HeaderTitle>Cookies Policy</HeaderTitle>
        <ParaHead>Last revised: October 2023</ParaHead>
        <ParaHead>
          <strong>About this Policy</strong>
        </ParaHead>
        <ContentPara>
          Our Privacy and Terms of Service Policies explain our principles when
          it comes to the collection, processing, and storage of your personal
          information. This policy explains the use of cookies in more details,
          such as what cookies are and how they are used. However, to get a full
          picture of how we handle your privacy this policy should be read
          together with our Privacy and Terms of Service Policies.
        </ContentPara>
        <ParaHead>
          <strong> What are cookies?</strong>
        </ParaHead>{" "}
        <ContentPara>
          Cookies are text files, containing small amounts of information, which
          are downloaded to your browsing device, such as your computer, mobile
          device or smartphone, when you visit our website or use our services.
          Cookies can be recognised by the website that downloaded them — or
          other websites that use the same cookies. This helps websites know if
          the browsing device has visited them before.
        </ContentPara>{" "}
        <ContentPara>
          We use two types of cookies: persistent cookies and session cookies. A
          persistent cookie lasts beyond the current session and is used for
          many purposes, such as recognizing you as an existing user, so it’s
          easier to return to us and interact with our services. Since a
          persistent cookie stays in your browser, it will be read by us when
          you return to one of our sites or visit a third-party site that uses
          our services. Session cookies last only as long as the session
          (usually the current visit to a website or a browser session).{" "}
        </ContentPara>
        <ParaHead>
          <strong>Do I need to accept cookies?</strong>
        </ParaHead>
        <ContentPara>
          No, you do not need to accept cookies. But, please be advised that if
          you do not accept cookies the service might be difficult or impossible
          to use.{" "}
        </ContentPara>
        <ContentPara>
          You can adjust settings on your browser so that you will be notified
          when you receive a cookie. Please refer to your browser documentation
          to check if cookies have been enabled on your computer or to request
          not to receive cookies. As cookies allow you to take advantage of some
          of the Website’s essential features, we recommend that you accept
          cookies. For instance, if you block or otherwise reject our cookies,
          you will not be able to use any products or services on the website
          that may require you to log in.
        </ContentPara>
        <ParaHead>
          <strong> What are the cookies used for? </strong>
        </ParaHead>
        <ContentPara>
          Functional Cookies Functional cookies are essential to provide our
          services as we want to provide them. They are used to remember your
          preferences on our website and to provide an enhanced and personalised
          experience. The information collected by these cookies is usually
          anonymised, so we cannot identify you personally. Functional cookies
          do not track your internet usage or gather information that could be
          used for selling advertising. These cookies are usually session
          cookies that will expire when you close your browsing session, but
          some are also persistent cookies.
        </ContentPara>
        <ParaHead>
          <strong>Essential or ‘Strictly Necessary’ Cookies</strong>
        </ParaHead>
        <ContentPara>
          These cookies are essential to provide our services. Without these
          cookies, parts of our website will not function. These cookies do not
          track where you have been on the internet and do remember preferences
          beyond your current visit and do not gather information about you that
          could be used for marketing purposes. These cookies are usually
          session cookies which will expire when you close your browsing
          session.{" "}
        </ContentPara>
        <ParaHead>
          <strong> Analytical Performance Cookies </strong>
        </ParaHead>
        <ContentPara>
          Analytical performance cookies are used to monitor the performance of
          our website and services, for example, to determine the number of page
          views and the number of unique users a website has. Web analytics
          services may be designed and operated by third parties. The
          information provided by these cookies allows us to analyse patterns of
          user behaviour and we use that information to enhance user experience
          or identify areas of the website which may require maintenance. The
          information is anonymous, cannot be used to identify you, does not
          contain personal information and is only used for statistical
          purposes.
        </ContentPara>
        <ParaHead>
          <strong>Advertising Cookies</strong>
        </ParaHead>
        <ContentPara>
          These cookies remember that you have visited a website and use that
          information to provide you with content or advertising which is
          tailored to your interests. They are also used to limit the number of
          times you see an advertisement as well as help measure the
          effectiveness of the advertising campaign. The information collected
          by these cookies may be shared with trusted third-party partners such
          as advertisers.{" "}
        </ContentPara>
        <ContentPara>
          We may update this Cookie Policy from time to time for operational,
          legal or regulatory reasons.
        </ContentPara>
        <ContentPara>
          If you have any questions regarding our policy on cookies please
          contact support@bestweb3.com
        </ContentPara>
      </div>
      <Footer />
    </>
  );
}
export default Cookies;
