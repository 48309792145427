import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import JoinWaitlistInputBox from "./JoinWaitlistInputBox";
import JoinWaitlistBtn from "./JoinWaitlistBtn";

// Define the type for the StyledContainer component
type StyledContainerProps = {
  submitted: boolean;
  scrollToButton: boolean;
};

const StyledContainer = styled.div<StyledContainerProps>`
  background-color: rgba(230, 234, 249, 0.83); /* 83% opacity */
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 100px;
  z-index: 90;
  position: absolute;
  overflow: hidden;
  font-family: poppins;
  &:not(.submitted)::selection {
    background-color: transparent;
    color: transparent;
    display: none;
  }
  @media (max-width: 750px) {
    // min-height: 154px;
    height: ${(props) => {
      if (!props.submitted) {
        if (props.scrollToButton) {
          return "200px";
        }
        return "148px";
      }
      return "auto";
    }};
    padding-bottom: 20px;
    border-radius: 0px;
    width: 100vw;
    padding-top: ${(props) => {
      if (!props.submitted) {
        if (props.scrollToButton) {
          return "20px";
        }
        return "20px";
      }
      return "60px";
    }};
    flex-direction: column;
    justify-content: ${(props) => {
      if (!props.submitted) {
        if (props.scrollToButton) {
          return "start";
        }
        return "start";
      }
      return "start";
    }};
    align-items: center;
  }
`;

function JoinWaitlist(props: any): ReactElement {
  const { submitted, input, setInput, err, setErr, CheckErr } = props;
  const [scrollToButton, setScrollToButton] = useState(false);
  const handleInputFocus = () => {
    setScrollToButton(true);
  };
  const handleInputBlur = () => {
    setScrollToButton(false); // You can implement any logic you need when the input box loses focus.
  };
  return (
    <StyledContainer submitted={submitted} scrollToButton={scrollToButton}>
      <JoinWaitlistInputBox
        {...{
          input,
          setInput,
          err,
          setErr,
          submitted,
          handleInputFocus,
          handleInputBlur,
        }}
      />
      <JoinWaitlistBtn {...{ CheckErr, submitted, scrollToButton }} />
    </StyledContainer>
  );
}

export default JoinWaitlist;
