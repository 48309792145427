interface FooterItem {
  name: string;
  link: string;
  redirect: string;
}

const FooterConstants: FooterItem[] = [
  {
    name: "twitter",
    link: "/footer_img/twitter.svg",
    redirect: "https://twitter.com/bestwallethq",
  },
  {
    name: "telegram",
    link: "/footer_img/telegram.svg",
    redirect: "https://t.me/Best_Wallet_Official",
  },
  {
    name: "discord",
    link: "/footer_img/discord.svg",
    redirect: "https://discord.gg/ujKmVVycsW",
  },
  {
    name: "linktree",
    link: "/footer_img/linktree.svg",
    redirect: "https://linktr.ee/bestwalletofficial",
  },
  {
    name: "insta",
    link: "/footer_img/insta.svg",
    redirect: "https://www.instagram.com/bestwallethq",
  },
];

export default FooterConstants;
