import React from "react";
import styled from "styled-components";

const FrameContentWrapper = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  width: 280px;
  height: auto;
  padding: 0 0px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 1200px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    width: 220px;
  }
`;

interface FrameContentProps {
  content: string;
}

function FrameContent({ content }: FrameContentProps): JSX.Element {
  return (
    <FrameContentWrapper>
      <div>{content}</div>
    </FrameContentWrapper>
  );
}

export default FrameContent;
