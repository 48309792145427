import React, { ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const BtnContainer = styled.a`
  margin-top: 20px;
  margin-bottom: 20px;
  outline: none;
  padding: 12px 20px;
  width: max-content; /* Adjust the width and height as needed */
  height: 45px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;
  background-color: #101828;
  border: 1px solid #101828;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    background-color: #5a63ff;
    color: white;
    border: none;
    border: 1px solid #5a63ff;
  }

  @media (max-width: 800px) {
    width: 300px;
    margin-top: 0;
  }
`;

function LearnMoreBtn(): ReactElement {
  const { t } = useTranslation();
  return (
    <BtnContainer href="https://bestwallet.com" target="_blank">
      {t("card_component.card_component_btn_text")}
    </BtnContainer>
  );
}

export default LearnMoreBtn;
