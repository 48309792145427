import React, { ReactElement, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavConstants from "../../constants/NavConstants";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";

const NavbarContent = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1440px) {
    max-width: 1216px;
  }
  @media (max-width: 700px) {
    height: 56px;
  }
  @media (min-width: 750px) and (max-width: 1250px) {
    margin: 0 10px;
  }
`;

const NavbarLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 835px) {
    display: none;
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  color: black;
`;

const SocialIcons = styled.div`
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 835px) {
    display: none;
  }
`;
const SocialIconsSmaller = styled.div`
  width: 270px;
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 835px) {
    display: flex;
  }
`;

const SocialIconContainer = styled.div`
  text-decoration: none;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    width: 32px;
    height: 32px;
  }
`;

const SocialIcon = styled.img`
  height: 12px;
  width: 12px;

  @media (max-width: 800px) {
    width: 15px;
    height: 15px;
  }
`;

const LogoImage = styled.img`
  /* Add your styling for the logo image here */
  @media (max-width: 835px) {
    display: none;
  }
`;

const Hamburger = styled.img`
  /* Add your styling for the logo image here */
  display: none;
  margin-left: 20px;
  @media (max-width: 835px) {
    display: block;
  }
`;

const CutIcon = styled.img`
  /* Add your styling for the logo image here */
  display: none;
  margin-left: 20px;
  @media (max-width: 835px) {
    display: block;
  }
`;

function Navbar(props: any): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    selectedLanguage,
    dropDownOpen,
    setDropDownOpen,
    navOpen,
    setNavOpen,
  } = props;
  // this state for when we click on home of navbar then automatically it shoud render on home section
  // const [activeLink, setActiveLink] = useState<string>("home");
  // keep navbar as bg color and when it scrolled ot should become as black bg
  const [scrolled, setScrolled] = useState<boolean>(false); // for home page keep false
  // this is trigged when scrolled start vertically
  const [hovered, setHovered] = useState("");
  useEffect(() => {
    // create function
    const onScroll = () => {
      // 50 will be our banner size..we change it as per req
      if (window.scrollY > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    // event fires when scrolled and pass the function
    window.addEventListener("scroll", onScroll);
    // when comp get remove from dom remove it that event and pass the same function
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // const scrollToComponent = (id: any) => {
  //   const element = document.getElementById(`${id}Carousel`);
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center", // Scroll so that the top of the element is at the top of the viewport
  //       inline: "nearest", // Keep the element as close to the center horizontally as possible
  //     });
  //   } else {
  //     const ele2 = document.getElementById(`${element}`);
  //     ele2?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center", // Scroll so that the top of the element is at the top of the viewport
  //       inline: "nearest", // Keep the element as close to the center horizontally as possible
  //     });
  //   }
  // };
  // function to update the state when this link are pressed
  // const onUpdateActiveLink = () => {
  //   setActiveLink(value);
  // };

  const handleInnerClick = (e: any) => {
    e.stopPropagation(); // Stop the event from propagating further
    // Your inner div click handling logic here
  };
  useEffect(() => {
    // Check if the URL contains "#features"
    if (window.location.hash === "#features") {
      // Scroll to the component with the ID "features"
      const featuresElement = document.getElementById("features");
      if (featuresElement) {
        featuresElement.scrollIntoView({ behavior: "smooth" });
      }
    } else if (window.location.hash === "#howitworks") {
      const howitwoksElem = document.getElementById("howitworks");
      if (howitwoksElem) {
        howitwoksElem.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [window.location.hash]);

  function goTop() {
    window.scrollTo(0, 0);
    if (window.location.hash) {
      window.history.replaceState(
        {},
        document.title,
        // eslint-disable-next-line prettier/prettier
        window.location.pathname + window.location.search
      );
      // setActiveLink("");
    }
  }

  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "64px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: "0",
        boxSizing: "border-box",
        overflow: "hidden",
        zIndex: 90,
        backgroundColor: scrolled ? "white" : "transparent",
        fontFamily: "Poppins, sans-serif",
      }}
      aria-label="close-dropdown"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setDropDownOpen(false);
        }
      }}
    >
      <NavbarContent>
        <div className="flex justify-between items-center">
          <LogoImage
            src="/bestCard_logo.svg"
            alt="Logo"
            onClick={() => {
              goTop();
              navigate(`/${selectedLanguage}`);
            }}
            style={{ cursor: "pointer" }}
          />
          {/* </Link> */}
          {navOpen ? (
            <CutIcon
              src="/cut.svg"
              alt="Logo"
              onClick={() => {
                setNavOpen((prev: boolean) => !prev);
                setDropDownOpen(false);
              }}
            />
          ) : (
            <Hamburger
              src="/hamburger.svg"
              alt="Logo"
              onClick={() => {
                setNavOpen((prev: boolean) => !prev);
                setDropDownOpen(false);
              }}
            />
          )}
        </div>

        <NavbarLinks
          onClick={() => {
            setDropDownOpen(false);
          }}
        >
          {NavConstants.map((e) => (
            <div
              style={{ padding: "4px 10px", margin: "0px 10px" }}
              key={e.link}
            >
              {e.internal ? (
                <Anchor
                  href={e.link}
                  onClick={() => {
                    // scrollToComponent(<FrameGroup />);
                    setDropDownOpen(false);
                    navigate("/");
                    // onUpdateActiveLink(e.val);
                  }}
                  style={{
                    textDecoration: "none",
                    color:
                      hovered === t(`nav_bar.nav_bar_links.${e.name}`)
                        ? "#5A63FF"
                        : "#344054",
                  }}
                  onMouseEnter={() =>
                    setHovered(t(`nav_bar.nav_bar_links.${e.name}`))
                  } // Handle hover state
                  onMouseLeave={() => setHovered("")} // Handle hover state
                >
                  {t(`nav_bar.nav_bar_links.${e.name}`)}
                </Anchor>
              ) : (
                <Link
                  to={e.link}
                  target={e.target && e.target}
                  style={{
                    textDecoration: "none",
                    color:
                      hovered === t(`nav_bar.nav_bar_links.${e.name}`)
                        ? "#5A63FF"
                        : "#344054",
                  }}
                  onClick={() => {
                    // onUpdateActiveLink(e.val);
                    setDropDownOpen(false);
                    navigate("/");
                  }}
                  onMouseEnter={() =>
                    setHovered(t(`nav_bar.nav_bar_links.${e.name}`))
                  } // Handle hover state
                  onMouseLeave={() => setHovered("")} // Handle hover state
                >
                  {t(`nav_bar.nav_bar_links.${e.name}`)}
                </Link>
              )}
            </div>
          ))}
        </NavbarLinks>

        <SocialIcons>
          <LanguageDropdown
            onClick={handleInnerClick}
            {...{ selectedLanguage, dropDownOpen, setDropDownOpen }}
          />

          <Link to="https://twitter.com/bestwallethq" target="_blank">
            <SocialIconContainer>
              <SocialIcon src="/footer_img/twitter.svg" alt="twitter" />
            </SocialIconContainer>
          </Link>

          <Link to="https://t.me/Best_Wallet_Official" target="_blank">
            <SocialIconContainer>
              <SocialIcon src="/footer_img/telegram.svg" alt="telegram" />
            </SocialIconContainer>
          </Link>
          <Link to="https://discord.gg/ujKmVVycsW" target="_blank">
            <SocialIconContainer>
              <SocialIcon src="/footer_img/discord.svg" alt="discord" />
            </SocialIconContainer>
          </Link>
          <Link to="https://linktr.ee/bestwalletofficial" target="_blank">
            <SocialIconContainer>
              <SocialIcon src="/footer_img/linktree.svg" alt="linktree" />
            </SocialIconContainer>
          </Link>
          <Link to="https://www.instagram.com/bestwallethq/" target="_blank">
            <SocialIconContainer>
              <SocialIcon
                src="/footer_img/insta.svg"
                alt="instagram"
                style={{ height: "25px", width: "25px" }}
              />
            </SocialIconContainer>
          </Link>
        </SocialIcons>

        <SocialIconsSmaller>
          <Link to="https://twitter.com/bestwallethq" target="_blank">
            <SocialIconContainer>
              <SocialIcon src="/footer_img/twitter.svg" alt="twitter" />
            </SocialIconContainer>
          </Link>

          <Link to="https://t.me/Best_Wallet_Official" target="_blank">
            <SocialIconContainer>
              <SocialIcon src="/footer_img/telegram.svg" alt="telegram" />
            </SocialIconContainer>
          </Link>
          <Link to="https://discord.gg/ujKmVVycsW" target="_blank">
            <SocialIconContainer>
              <SocialIcon src="/footer_img/discord.svg" alt="discord" />
            </SocialIconContainer>
          </Link>
          <Link to="https://linktr.ee/bestwalletofficial" target="_blank">
            <SocialIconContainer>
              <SocialIcon src="/footer_img/linktree.svg" alt="linktree" />
            </SocialIconContainer>
          </Link>
          <Link to="https://www.instagram.com/bestwallethq/" target="_blank">
            <SocialIconContainer>
              <SocialIcon
                src="/footer_img/insta.svg"
                alt="instagram"
                style={{ height: "25px", width: "25px" }}
              />
            </SocialIconContainer>
          </Link>

          <LanguageDropdown
            onClick={handleInnerClick}
            {...{ selectedLanguage, dropDownOpen, setDropDownOpen }}
          />
        </SocialIconsSmaller>
      </NavbarContent>
    </div>
  );
}

export default Navbar;
