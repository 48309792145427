interface HowToUseBestCard {
  img: string;
}

const HowToUseBestCardConstants: HowToUseBestCard[] = [
  {
    img: "/howToUseBestCards/Component_1.svg",
  },
  {
    img: "/howToUseBestCards/Component_2.svg",
  },
  {
    img: "/howToUseBestCards/Component_3.svg",
  },
  {
    img: "/howToUseBestCards/Component_4.svg",
  },
];

export default HowToUseBestCardConstants;
