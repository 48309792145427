import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";
import frameCardConstants from "../../constants/FrameCardConstants";

const MainBox = styled.div`
  width: 100vw;
  height: 28px;
  margin-top: 100px;
  display: none;
  justify-content: center;
  align-items: start;
  @media (max-width: 800px) {
    margin-top: 80px;
  }
  @media (max-width: 600px) {
    display: flex;
  }
`;

const CardBox = styled.div`
  width: 335px;
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const FrameCard = styled.div`
  width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const CircleWrapper = styled.div`
  width: 50px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function CircleBox(props: any): JSX.Element {
  const { currSlide, setCurrSlide, frameCardConstants } = props;
  return (
    <CircleWrapper>
      {frameCardConstants.map((e: any, i: any) => (
        <div
          role="button" // Add role="button" to indicate it's an interactive element
          tabIndex={0}
          aria-label={`Slide ${i + 1}`} // Add a descriptive label
          style={{
            width: "13px",
            height: "13px",
            borderRadius: "100%",
            border: "1px solid #CECECE",
            backgroundColor: currSlide === i ? "#5A63FF" : "transparent",
            cursor: "pointer",
          }}
          onClick={() => {
            setCurrSlide(i);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              setCurrSlide(i);
            }
          }}
        />
      ))}
    </CircleWrapper>
  );
}

function FrameCarousal(): JSX.Element {
  const [currSlide, setCurrSlide] = useState(0);
  const { t } = useTranslation();
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currSlide < frameCardConstants.length - 1) {
        setCurrSlide(currSlide + 1);
      } else {
        setCurrSlide(0);
      }
    },
    onSwipedRight: () => {
      if (currSlide > 0) {
        setCurrSlide(currSlide - 1);
      } else {
        setCurrSlide(frameCardConstants.length - 1);
      }
    },
  });
  return (
    <MainBox style={{ height: "340px" }} id="featuresCarousel" {...handlers}>
      <CardBox>
        <FrameCard
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            height: "auto",
          }}
        >
          <img
            src={frameCardConstants[currSlide].img}
            style={{ width: "335px", height: "220px" }}
            alt="carousalImg"
          />

          <div
            style={{
              width: "265px",
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "400",
              padding: "0 25px",
            }}
          >
            {t(`top_carousel.${frameCardConstants[currSlide].content}`)}
          </div>
        </FrameCard>
        <CircleBox {...{ currSlide, setCurrSlide, frameCardConstants }} />
        {/* ))} */}
      </CardBox>
    </MainBox>
  );
}

export default FrameCarousal;
