interface FrameCard {
  img: string;
  content: string;
}

const FrameCardConstants: FrameCard[] = [
  {
    img: "/frameCard_images/support-by-google and samsung.svg",
    content: "supported_by",
  },
  {
    img: "/frameCard_images/Earn-cashback.svg",
    content: "earn_cashback",
  },
  {
    img: "/frameCard_images/industry lowest fee.svg",
    content: "industry_lowest_fees",
  },
];

export default FrameCardConstants;
