import React, { ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import HowToUseBestCardConstants from "../../constants/HowToUseBestCardConstants";
import BestCard from "./BesCard";
// import isVisible from "../../utils/viewChecker";

interface CardData {
  img: string;
}

const MainBox = styled.div`
  width: 100vw;
  height: auto;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f8fa;
  @media (max-width: 1200px) {
    height: auto;
    margin-top: 0px;
    display: none;
  }

  @media (max-width: 800px) {
    display: none;
    margin-top: 80px;
  }
`;

const TitleContainerWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  overflow: hidden;
  margin-bottom: 100px;
  @media (max-width: 1440px) {
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
  }
  @media (max-width: 1200px) {
    width: 800px;
  }
  @media (max-width: 700px) {
    font-weight: 400;
    font-size: 36px;
  }
`;

const CardBox = styled.div`
  width: 1440px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: start;
  @media (max-width: 1440px) {
    width: 1216px;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    width: 630px;
  }
`;

interface TitleContainerProps {
  translatedTitle: string;
}

function TitleContainer({
  translatedTitle,
}: TitleContainerProps): ReactElement {
  return <TitleContainerWrapper>{translatedTitle}</TitleContainerWrapper>;
}

function HowToUseBestCard(): ReactElement {
  const { t } = useTranslation();
  const translatedText: string[] = [
    "download_best_wallet_app",
    "fund_your_wallet_with_crypto",
    "spend_your_crypto_anywhere",
    "earn_cashback_on_every_transaction",
  ];

  return (
    <>
      <div id="howitworks" />
      <MainBox>
        <TitleContainer
          translatedTitle={t(`bottom_carousel.bottom_carousel_title`)}
        />
        <CardBox>
          {HowToUseBestCardConstants.map(
            (e: CardData, i: number): ReactElement => (
              <BestCard
                imageProps={e}
                ind={i}
                key={e.img}
                text={t(
                  // eslint-disable-next-line prettier/prettier
                  `bottom_carousel.bottom_carousel_contents.${translatedText[i]}`
                )}
              />
              // eslint-disable-next-line prettier/prettier
            )
          )}
        </CardBox>
      </MainBox>
    </>
  );
}

export default HowToUseBestCard;
