const validLanguages = [
  "",
  "ar",
  "bgr",
  "cs",
  "de",
  "en",
  "es",
  "fr",
  "hu",
  "id",
  "it",
  "ja",
  "ko",
  "lv",
  "nl",
  "pl",
  "pt",
  "ro",
  "ru",
  "sk",
  "th",
  "tr",
  "vi",
  "zhs",
  "zht",
];

export default validLanguages;
