import React from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import CarouselConstants from "../../constants/CarouselConstants";

const CarouselContainer = styled.div`
  width: 100vw;
  bottom: 10px;
  overflow: hidden;
  position: absolute;
  overflow-x: hidden;
  z-index: 50;
  background-color: transparent;
  @media (min-width: 750px) and (max-width: 1250px) {
    bottom: 70px;
  }
`;

const StyledImage = styled.img`
  height: auto;
  width: 14vw;
  @media (max-width: 800px) {
    width: 24vw;
  }

  @media (max-width: 500px) {
    width: 38vw;
  }
`;

const itemStyle: React.CSSProperties = {
  margin: "0 3vw",
  boxSizing: "border-box",
};

function ResponsiveCarousel(): JSX.Element {
  return (
    <CarouselContainer>
      <Marquee speed={100}>
        {CarouselConstants.map((imageUrl: string) => (
          <div style={itemStyle} key={imageUrl}>
            <StyledImage src={imageUrl} alt="url" />
          </div>
        ))}
      </Marquee>
    </CarouselContainer>
  );
}

export default ResponsiveCarousel;
