import { useState } from "react";
import "./styles.css";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MainBox = styled.div`
  width: 100vw;
  height: auto;
  padding: 0px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f7f8fa;
`;

const CardBox = styled.div`
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @media (max-width: 1440px) {
    max-width: 1216px;
  }

  @media (max-width: 700px) {
    width: 100%;
    margin: 0 20px;
  }
`;

const AccordionCard = styled.div`
  width: 100%;
  height: auto;
  min-height: 90px;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  justify-content: start;
  align-items: start;
  margin-bottom: 15px;
  cursor: pointer;
  background-color: white;

  @media (max-width: 600px) {
  }
`;

const TextContainer = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #1f2933;
  max-width: 1000px;
  width: 90%;
  @media (max-width: 600px) {
  }
`;

const AccordionTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  @media (max-width: 600px) {
  }
`;

function Accordion() {
  const [active, setActive] = useState(null);
  const { t } = useTranslation();

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const faqs = [
    {
      id: 1,
      header: t("faq.q_and_a.first_question"),
      text: t("faq.q_and_a.first_answer"),
    },
    {
      id: 2,
      header: t("faq.q_and_a.second_question"),
      text: t("faq.q_and_a.second_answer"),
    },
    {
      id: 3,
      header: t("faq.q_and_a.third_question"),
      text: t("faq.q_and_a.third_answer"),
    },
    {
      id: 4,
      header: t("faq.q_and_a.fourth_question"),
      text: t("faq.q_and_a.fourth_answer"),
    },
    {
      id: 5,
      header: t("faq.q_and_a.fifth_question"),
      text: t("faq.q_and_a.fifth_answer"),
    },
    {
      id: 6,
      header: t("faq.q_and_a.sixth_question"),
      text: t("faq.q_and_a.sixth_answer"),
    },
    {
      id: 7,
      header: t("faq.q_and_a.seventh_question"),
      text: t("faq.q_and_a.seventh_answer"),
      secText: t("faq.q_and_a.point_one"),
      thirdText: t("faq.q_and_a.point_two"),
      forthText: t("faq.q_and_a.coming_soon"),
      fifthText: t("faq.q_and_a.best_bot"),
      sixthText: t("faq.q_and_a.best_card"),
      seventhText: t("faq.q_and_a.we_are_building"),
    },
  ];

  return (
    <MainBox>
      <CardBox>
        {faqs.map((e) => (
          <AccordionCard onClick={() => handleToggle(e.id)}>
            <img
              src={
                active !== e.id
                  ? "/assets/plus-circle.svg"
                  : "/assets/minus-circle.svg"
              }
              alt="icon"
              style={{
                height: "24px",
                width: "32px",
                marginRight: "24px",
              }}
            />
            <TextContainer>
              <AccordionTitle>
                <strong>{e.header}</strong>
              </AccordionTitle>
              <div
                style={{
                  display: active === e.id ? "block" : "none",
                  paddingTop: "8px",
                }}
              >
                {e.text}
              </div>

              {e.secText && (
                <div style={{ display: active === e.id ? "block" : "none" }}>
                  <ul>
                    {e.secText && (
                      <li
                        style={{
                          listStyle: "inside",
                          listStyleType: "disc",
                          color: "black",
                        }}
                      >
                        <strong>
                          {t("faq.q_and_a.point_one").split(":")[0]}:
                        </strong>{" "}
                        {t("faq.q_and_a.point_one").split(":")[1]}
                      </li>
                    )}
                    {e.thirdText && (
                      <li
                        style={{
                          listStyle: "inside",
                          listStyleType: "disc",
                          color: "black",
                        }}
                      >
                        <strong>
                          {t("faq.q_and_a.point_two").split(":")[0]}:
                        </strong>{" "}
                        {t("faq.q_and_a.point_two").split(":")[1]}
                      </li>
                    )}{" "}
                  </ul>
                  {e.forthText && <div>{e.forthText}</div>}
                  <ul>
                    {e.fifthText && (
                      <li
                        style={{
                          listStyle: "inside",
                          listStyleType: "disc",
                          color: "black",
                        }}
                      >
                        <strong>
                          {t("faq.q_and_a.best_bot").split(":")[0]}:
                        </strong>{" "}
                        {t("faq.q_and_a.best_bot").split(":")[1]}
                      </li>
                    )}
                    {e.sixthText && (
                      <li
                        style={{
                          listStyle: "inside",
                          listStyleType: "disc",
                          color: "black",
                        }}
                      >
                        <strong>
                          {t("faq.q_and_a.best_card").split(":")[0]}:
                        </strong>{" "}
                        {t("faq.q_and_a.best_card").split(":")[1]}
                      </li>
                    )}
                  </ul>
                  {e.seventhText && <div>{e.seventhText}</div>}
                </div>
              )}
            </TextContainer>
          </AccordionCard>
        ))}
      </CardBox>
    </MainBox>
  );
}

export default Accordion;
