interface MajorContent {
  img: string;
  smallImg?: string;
}

const MajorContentConstants: MajorContent[] = [
  {
    img: "/majorContent/cashback.svg",
    smallImg: "/majorContent/cashback3x.png",
  },
  {
    img: "/majorContent/low.svg",
    smallImg: "/majorContent/lowto3x.png",
  },
  {
    img: "/majorContent/convert.svg",
    smallImg: "/majorContent/convert3x.png",
  },
];

export default MajorContentConstants;
