import React from "react";
import styled from "styled-components";

interface ContentBoxProps {
  ind: number;
  text: string;
}

const ContentBoxWrapper = styled.div`
  width: 240px;
  // height: 114px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 32px 32px 0px 32px;
  @media (max-width: 1200px) {
    width: 200px;
    height: auto;
    margin-bottom: 0px;
    background-color: #f7f8fa;
  }
`;

const NumberDiv = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 100px;
  border: 4px solid #f1f2ff;
  background-color: #a0acd4;
  color: white;
`;

const ContentDiv = styled.div`
  width: 200px;
  height: auto;
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20.8px;
`;

function ContentBox({ ind, text }: ContentBoxProps): JSX.Element {
  return (
    <ContentBoxWrapper>
      <NumberDiv>{ind + 1}</NumberDiv>
      <ContentDiv>{text}</ContentDiv>
    </ContentBoxWrapper>
  );
}

export default ContentBox;
