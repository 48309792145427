import React, { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import styled from "styled-components";

const FlagImage = styled.div<{ flag: string }>`
  width: 22px;
  height: 22px;
  background-image: url(${(props) => `/flags/${props.flag}.svg`});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 5px;
  @media (max-width: 800px) {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
`;

function LanguageDropdown(props: any) {
  const { selectedLanguage, dropDownOpen, setDropDownOpen } = props;
  const [language, setLanguage] = useState("en");
  useEffect(() => {
    // console.log(selectedLanguage, "selected");
    if (!selectedLanguage) {
      setLanguage("en");
    } else if (selectedLanguage === "en-GB" || selectedLanguage === "en-US") {
      setLanguage("en");
    } else {
      setLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);
  useEffect(() => {
    document.body.style.overflowY = dropDownOpen ? "hidden" : "scroll";
  }, [dropDownOpen]);

  return (
    <div
      style={{
        width: "auto",
        height: "auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: "15px",
        cursor: "pointer",
      }}
      onClick={() => {
        setDropDownOpen((prev: boolean) => !prev);
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          setDropDownOpen((prev: boolean) => !prev);
        }
      }}
    >
      <FlagImage flag={language} />
      <IoIosArrowUp
        style={{
          transform: !dropDownOpen ? "rotate(180deg)" : "rotate(0deg)",
          // display: !dropDownOpen ? "none" : "none",
        }}
      />
    </div>
  );
}

export default LanguageDropdown;
