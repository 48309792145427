import styled from "styled-components";
import { useTranslation } from "react-i18next";

import "react-accessible-accordion/dist/fancy-example.css";
import Accordion from "./Accordion.jsx";

const MainBox = styled.div`
  width: 100vw;
  height: auto;
  padding: 0px 0;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f7f8fa;

  @media (max-width: 700px) {
    margin-top: 80px;
  }
`;

const CardBox = styled.div`
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @media (max-width: 1440px) {
    max-width: 1216px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 100px;

  @media (max-width: 1440px) {
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
  }
  @media (max-width: 700px) {
    font-weight: 400;
    font-size: 36px;
  }

  @media (max-width: 600px) {
    width: 325px;
    text-align: center;
    margin-bottom: 80px;
  }
`;

function FAQ(): JSX.Element {
  const { t } = useTranslation();
  return (
    <MainBox>
      <CardBox>
        <Title>{t("faq.faq_title")}</Title>
        <Accordion />
      </CardBox>
    </MainBox>
  );
}

export default FAQ;
