import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ContentBox = styled.div`
  margin-top: 0px;
  z-index: 40;
  overflow: hidden;
  height: auto;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: auto;
  }

  @media (min-width: 1650px) {
    // width: 800px;
  }

  @media (max-width: 350px) {
    width: 320px;
    padding-left: 2px;
    padding-right: 2px;
  }
  @media (max-width: 870px) {
    width: 600px;
  }
`;

const GradientText = styled.div`
  background-image: linear-gradient(
    107.26deg,
    #cc99df -6.61%,
    #8798ef 18.3%,
    #6f7bf7 38.59%,
    #5860ff 56.28%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 60px;
  font-weight: 600;
  line-height: 45px;
  margin-bottom: 10px;
  letter-spacing: 0.03em;
  text-align: left;
  overflow: hidden;

  @media (max-width: 1440px) {
    margin-bottom: 0px;
    font-size: 48px;
    font-weight: 600;
    line-height: 40px;
  }

  @media (max-width: 700px) {
    display: none;
  }
  @media (min-width: 700px) and (max-width: 1250px) {
    padding-left: 1rem;
  }
`;

const Title = styled.div`
  font-size: 60px;
  line-height: 80px;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-align: left;
  overflow: hidden;
  width: 700px;
  @media (max-width: 1440px) {
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
  }
  @media (max-width: 1250px) {
    width: 500px;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    height: auto;
  }
  @media (min-width: 700px) and (max-width: 1250px) {
    text-align: left;
  }
  @media (min-width: 700px) and (max-width: 870px) {
    height: auto;
  }
  @media (min-width: 1650px) {
    font-size: 60px;
    // line-height: 58px;
    font-weight: 600;
    margin-bottom: 0px;
    width: 700px;
  }
  @media (max-width: 840px) {
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    width: 360px;
  }

  @media (max-width: 350px) {
    height: auto;
  }
  @media (min-width: 1440px) {
    // line-height: 70px;
  }
`;

const TopTitleContainer = styled.div`
  @media (max-width: 1250px) {
    padding-top: 10px;
    text-align: center;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  @media (min-width: 700px) and (max-width: 1250px) {
    align-items: start;
    width: auto;
    padding-left: 1rem;
  }
  @media (min-width: 1650px) {
    font-size: 30px;
    // line-height: 35px;
    margin-bottom: 50px;
    width: auto;
    padding-top: 0px;
    // height: 100px;
  }

  @media (max-width: 350px) {
    height: auto;
  }
`;

const Punch = styled.div`
  width: 450px;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
  margin-top: 8px;
  overflow: hidden;
  @media (min-width: 1650px) {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 50px;
    height: auto;
  }
  @media (max-width: 1440px) {
    width: 390px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
  @media (max-width: 1250px) {
    line-height: 22px;
    align-content: center;
    display: flex;
    // align-items: center;
    // justify-content: center;
    text-align: center;
    margin-top: 8px;
    marginbottom: 40px;
    letter-spacing: 0;
    width: 350px;
  }
  @media (max-width: 870px) {
    font-size: 18px;
    font-weight: 400;
  }
  @media (min-width: 700px) and (max-width: 870px) {
  }
  @media (min-width: 700px) and (max-width: 1250px) {
    text-align: left;
  }
  @media (max-width: 700px) {
  }
  @media (max-width: 374px) {
  }
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @media (max-width: 1250px) {
  }

  @media (min-width: 900px) {
    display: none;
  }
  @media (min-width: 700px) and (max-width: 1250px) {
    display: none;
  }
`;

function HeroSectionContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <ContentBox>
      <LogoBox>
        <img src="/bestCard_logo.svg" alt="Logo" />
      </LogoBox>
      <GradientText>{t(`landing_page.landing_page_title`)}</GradientText>
      <TopTitleContainer>
        <Title>{t(`landing_page.secondary_title`)}</Title>
        <Punch>{t(`landing_page.punch_line`)}</Punch>
      </TopTitleContainer>
    </ContentBox>
  );
}

export default HeroSectionContent;
