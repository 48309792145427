import React, { ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledButton = styled.div`
  height: 48px;
  min-width: 178px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5a63ff; /* Replace with your desired background color */
  border-radius: 24px; /* Half of the height to create a rounded shape */
  color: #fff; /* Text color */
  margin-left: 16px;
  border: 2px solid transparent; /* Add a transparent border by default */
  cursor: pointer;
  padding: 0 10px;
  font-family: poppins;
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  @media (max-width: 875px) {
    padding: 10px;
    text-align: center;
  }
  @media (max-width: 750px) {
    width: 335px;
    margin-left: 0px;
    font-family: SanFranciscoPro, sans-serif !important;
  }
`;

function JoinWaitlistBtn(props: any): ReactElement {
  const { t } = useTranslation();
  const { CheckErr, submitted } = props;
  return (
    <>
      {!submitted && (
        <StyledButton
          onClick={() => {
            CheckErr();
          }}
        >
          {t(`join_wait_list.join_wait_list_btn_text`)}
        </StyledButton>
      )}
      {/* Other content in your component */}
    </>
  );
}

export default JoinWaitlistBtn;
