interface NavItem {
  name: string;
  link: string;
  val: string;
  target?: string;
  internal?: boolean;
}

const NavConstants: NavItem[] = [
  {
    name: "features_first",
    link: "#features",
    val: "features",
    internal: true,
  },
  {
    name: "how_it_works",
    link: "#howitworks",
    val: "howitworks",
    internal: true,
  },
  {
    name: "best_wallet",
    link: "https://bestwallet.com/",
    val: "bestwallet",
    target: "_blank",
  },
];

export default NavConstants;
