import React from "react";
import styled from "styled-components";
import MajorContentImg from "./MajorContentImg";
import MajorContentText from "./MajorContentText";

// Define a custom prop for MainBox
interface MainBoxProps {
  i: number;
}

const MainBox = styled.div<MainBoxProps>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f8fa;
  margin-bottom: 100px;
  overflow: hidden;
  // Conditionally set the order based on props.i
  & > :nth-child(1) {
    order: ${(props) => (props.i % 2 === 0 ? 1 : 2)};
  }
  & > :nth-child(2) {
    order: ${(props) => (props.i % 2 === 0 ? 2 : 1)};
  }
  @media (max-width: 1200px) {
    margin-bottom: 80px;
  }
  @media (max-width: 750px) {
    margin-bottom: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 1;
    // margin-bottom: 0px;

    // Conditionally set the order based on props.i
    & > :nth-child(1) {
      order: 1;
    }
    & > :nth-child(2) {
      order: 2;
    }
  }
`;

interface MajorContentCardProps {
  i: number;
  imgObj: any;
  contentBox: any;
  smallImg?: string;
}

function MajorContentCard(props: MajorContentCardProps) {
  const { i, imgObj, contentBox } = props;
  const { img, smallImg } = imgObj;
  return (
    <MainBox i={i}>
      <MajorContentImg {...{ img, smallImg }} />
      <MajorContentText textProps={contentBox} />
    </MainBox>
  );
}

export default MajorContentCard;
