const CarouselConstants: string[] = [
  "/caroussel_images/bitcoinist.svg",
  "/caroussel_images/bitforex.svg",
  "/caroussel_images/coindesk.svg",
  "/caroussel_images/cointelegraph.svg",
  "/caroussel_images/cryptonews.svg",
  "/caroussel_images/techopedia.svg",
  "/caroussel_images/Uniswapbeincrypto.svg",
];

export default CarouselConstants;
