import React, { ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MajorContentCard from "./MajorContentCard";
import MajorContentConstants from "../../constants/MajorContentConstants";

interface ContentBox {
  title: string;
  content: string;
  secondContent?: string;
}

const MainBox = styled.div`
  width: 100vw;
  height: auto;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f8fa;
  overflow: hidden;
  @media (max-width: 1200px) {
    margin-top: 80px;
  }
`;

const CardBox = styled.div`
  width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  overflow: hidden;
  @media (max-width: 1440px) {
    width: 1216px;
  }
  @media (max-width: 1200px) {
    width: 800px;
  }

  @media (max-width: 800px) {
    width: 700px;
  }
`;

function MajorContent(): ReactElement {
  const { t } = useTranslation();
  const majorContentBoxTranslatedContent: ContentBox[] = [
    {
      title: t(`major_content.major_content_first_title`),
      content: t(`major_content.major_content_first_content`),
      secondContent: t(`major_content.major_content_first_second_content`),
    },
    {
      title: t(`major_content.major_content_second_title`),
      content: t(`major_content.major_content_second_content`),
    },
    {
      title: t(`major_content.major_content_third_title`),
      content: t(`major_content.major_content_third_content`),
    },
  ];

  return (
    <MainBox>
      <CardBox>
        {MajorContentConstants.map((e, i) => (
          <MajorContentCard
            imgObj={e}
            i={i}
            key={majorContentBoxTranslatedContent[i].title}
            contentBox={majorContentBoxTranslatedContent[i]}
          />
        ))}
      </CardBox>
    </MainBox>
  );
}

export default MajorContent;
