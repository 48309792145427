import validLanguages from "./LanguageConstants";

function urlReplacer(selectedLanguage: string) {
  const currentURL = new URL(window.location.href);
  let newPathname: any = currentURL.pathname.split("/");
  if (validLanguages.includes(newPathname[newPathname.length - 1])) {
    newPathname =
      // eslint-disable-next-line prefer-template
      newPathname.slice(0, -1).join("/") + `/${selectedLanguage}`;
  } else {
    // eslint-disable-next-line prefer-template
    newPathname = newPathname.join("/") + `/${selectedLanguage}`;
  }
  currentURL.pathname = newPathname;
  // Update the URL without triggering a page reload
  window.history.replaceState({}, "", currentURL.href);
}

export default urlReplacer;
