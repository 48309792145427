import React, { useState, useEffect, ReactElement } from "react";
import styled from "styled-components";
import JoinWaitlist from "../JoinWaitlist/JoinWaitlist";

type StyledTop = {
  bottomPosition: any;
};
const StyledTopBottomBox = styled.div<StyledTop>`
  position: fixed;
  width: 100vw;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  box-sizing: border-box;
  z-index: 70;
  &:not(.submitted)::selection {
    background-color: transparent;
    color: transparent;
    display: none;
  }
  overflow: hidden;
  padding: 0 0 30px 0;
  transition: opacity 8s; /* Add opacity transition for fade effect */
  bottom: ${(props) => (props.bottomPosition ? "350px" : "50px")};
  @media (max-width: 750px) {
    height: 200px;
    bottom: ${(props) => (props.bottomPosition ? "1000px" : "0px")};
    padding: 0;
  }
  &.hidden {
    opacity: 0; /* Hide the element when it has the "hidden" class */
  }
  @media (min-width: 750px) and (max-width: 1250px) {
    bottom: ${(props) => (props.bottomPosition ? "350px" : "120px")};
  }
  @media (min-width: 1750px) {
    bottom: ${(props) => (props.bottomPosition ? "350px" : "120px")};
  }
`;
type StyledContainerProps = {
  submitted: boolean;
};
const StyledShadow = styled.div<StyledContainerProps>`
  // width: ${(props) => (props.submitted ? "400px" : "500px")};
  height: auto;
  bottom: -5px;
  position: absolute;
  background: transparent;
  border-radius: 200px;
  // z-index: 999;
  // margin-top: 100px;
  // box-shadow: 3px 3px 3px;
  transform: rotate(180deg) scaleY(-1);
  // opacity: 0.4;
  // filter: ${(props) => (props.submitted ? "0" : "blur(20px)")};
  overflow: hidden;
  &:not(.submitted)::selection {
    background-color: transparent;
    color: transparent;
    display: none;
  }
  display: ${(props) => (props.submitted ? "none" : "block")};
  @media (max-width: 750px) {
    display: none;
  }
`;
function TopBottomBox(): ReactElement {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [err, setErr] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setPrevScrollPosition(scrollPosition);
      setScrollPosition(window.scrollY);
    };
    // Add event listener for scroll events
    window.addEventListener("scroll", handleScroll);
    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);
  const isScrollingDown = scrollPosition > prevScrollPosition;
  const bottomPosition =
    scrollPosition >= document.body.scrollHeight - window.innerHeight &&
    isScrollingDown;
  const postData = () => {
    if (!process.env.REACT_APP_FORMADDRESS) {
      return;
    }
    fetch(process.env.REACT_APP_FORMADDRESS, {
      method: "POST",
      body: JSON.stringify({ email: input }),
      // eslint-disable-next-line prettier/prettier
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch.
        console.error("Fetch Error:", error);
      });
  };
  const CheckErr = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(input)) {
      setErr(true);
    } else {
      postData();
      setSubmitted(true);
    }
  };
  return (
    <StyledTopBottomBox
      className={submitted ? "hidden" : ""}
      bottomPosition={bottomPosition}
    >
      <StyledShadow submitted={submitted}>
        <img
          src="/waitlistShadow.svg"
          alt=""
          style={{
            width: "100%",
            WebkitTouchCallout: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
          }}
        />
      </StyledShadow>
      <JoinWaitlist
        {...{
          err,
          setErr,
          input,
          setInput,
          submitted,
          setSubmitted,
          CheckErr,
        }}
      />
    </StyledTopBottomBox>
  );
}
export default TopBottomBox;
