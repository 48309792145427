import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer/Footer";

const HeaderTitle = styled.div`
  text-align: start;
  font-size: 48px;
  line-height: 1.08349;
  font-weight: 600;
  letter-spacing: -0.003em;
  margin: 100px 0 20px;
  @media only screen and (max-width: 600px) {
    font-size: 32px;
  }
`;
const ParaContainer = styled.div``;
const ParaHead = styled.div`
  color: #000;
  font-size: 20px;
  margin-bottom: 1rem;
`;

const NumberedList = styled.ol`
  list-style-type: decimal;
  margin-bottom: 0rem;
`;
const AlphaList = styled.ol`
  list-style-type: lower-alpha;
  margin-bottom: 0rem;
`;
const ListItem = styled.li`
  padding-left: 0rem;
  color: #000;
  font-size: 20px;
  margin-bottom: 16px;
  ${AlphaList} & {
    padding-left: 20px;
  }
`;
const ListItemno = styled.div`
  padding-top: 0.5rem;
  padding-left: 0rem;
  color: #000;
  font-size: 20px;
`;

function TermsAndConditions() {
  return (
    <>
      <div
        className="container"
        style={{ maxWidth: "1024px", padding: "20px" }}
      >
        <HeaderTitle>Terms Of Service</HeaderTitle>
        <ParaContainer>
          <ParaHead>
            These terms of use (Terms of Use) govern your use of our Platforms,
            including our website (www.bestcard.com), mobile applications and
            other digital platforms.
          </ParaHead>
          <ParaHead>
            Please read these Terms of Use carefully before accessing or using
            our Platforms. By accessing or using our Platforms, you signify your
            acceptance, assent and agreement to these Terms of Use. If you do
            not agree to these Terms of Use, then you are not authorised to
            continue to use our Platforms.
          </ParaHead>
          <NumberedList>
            <strong>
              <ListItem>About Us</ListItem>
            </strong>
            <AlphaList>
              <ListItem>
                Best Card (we, our and us), is an International Business Company
                based in the Marshall Islands. We are the developers of the Best
                Card.
              </ListItem>
              <ListItem>
                As we continue to better enhance your experience, we are also
                looking to roll out mobile applications and other digital
                platforms in time. Our Website, mobile applications and other
                digital platforms as well as smart contracts (collectively, our
                Platforms) are or shall be owned and edited by us.
              </ListItem>
              <ListItem>
                The information and content on our Platforms are not intended
                for distribution to or used by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country. If
                you access our Platforms from such other locations, you do so on
                your own initiative and you are solely responsible for
                compliance with local laws, if and to the extent local laws are
                applicable.
              </ListItem>
              <ListItem>
                In order to use our Platforms, you must not be included in any
                trade embargoes or economic sanctions lists (such as United
                Nations Security Council Sanctions List), the list of specially
                designated nationals maintained by OFAC (the Office of Foreign
                Assets Control of the U.S. Department of the Treasury), or the
                denied persons or entities list of the U.S. Department of
                Commerce.
              </ListItem>
              <ListItem>
                Our Platforms are intended for users who are at least 18 years
                old. Persons under the age of 18 are not permitted to use our
                Platforms.
              </ListItem>
            </AlphaList>
            <strong>
              <ListItem>Our Privacy Policy</ListItem>
            </strong>
            <AlphaList>
              <ListItem>
                In addition to these Terms of Use, our Privacy Policy, which
                sets out the terms on which we process any personal data we
                collect from you, or that you provide to us, also applies to
                your use of our Platforms.
              </ListItem>
            </AlphaList>
            <strong>
              <ListItem>About our Hosting Services</ListItem>
            </strong>
            <AlphaList>
              <ListItem>
                Hosting services for our Website is provided by AWS and we use
                AWS Network Firewall as a firewall for protection.
              </ListItem>
              <ListItem>
                Our mobile applications (If launched) may be hosted by us or by
                other companies and you should refer to the relevant conditions
                of use of such other companies.
              </ListItem>
            </AlphaList>
            <strong>
              <ListItem>Changes to these Terms of Use</ListItem>
            </strong>
            <AlphaList>
              <ListItem>
                We may make changes from time to time to these Terms of Use by
                amending this page. Please check this page regularly to stay
                informed of any changes we made, as they are binding on you.
              </ListItem>
              <ListItem>
                The latest version of these Terms of Use will always be
                available on our Website. Any new version of these Terms of Use
                will take effect and govern the use of our Platforms and your
                relationship with us immediately upon the date of posting. By
                continuing to use our Platforms, you acknowledge, accept and
                agree to be bound by the terms of these updates and amendments.
              </ListItem>
            </AlphaList>
            <strong>
              <ListItem>Changes to our Platforms</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                We may update our Platforms from time to time, and may change
                the content at any time. However, please note that any of the
                content on our Platforms may be out of date at any given time,
                and we are under no obligation to update it.
              </ListItem>
              <ListItem>
                We do not guarantee that our Platforms, or any content on it,
                will be free from errors or omissions.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>Accessing our Platforms</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                Our Platforms are made available free of charge.
              </ListItem>
              <ListItem>
                We do not guarantee that our Platforms, or any content on them,
                will always be available or be uninterrupted. Access to our
                Platforms is permitted on a temporary basis. We may suspend,
                withdraw, discontinue or change all or any part of our Platforms
                without notice. We will not be liable to you if for any reason
                our Platforms are unavailable at any time or for any period.
              </ListItem>{" "}
              <ListItem>
                You are responsible for making all arrangements necessary for
                you to have access to our Platforms.
              </ListItem>{" "}
              <ListItem>
                You are also responsible for ensuring that all persons who
                access our Platforms through your internet connection or device
                are aware of these Terms of Use and other applicable terms and
                conditions, and that they comply with them.
              </ListItem>{" "}
              <ListItem>
                You must not use our Platforms for any illegal or unauthorised
                purpose.
              </ListItem>{" "}
              <ListItem>
                You must not access our Platforms through automated and
                non-human means, whether through a bot, script or otherwise.
              </ListItem>{" "}
              <ListItem>
                We do not represent that content available on or through our
                Platforms is appropriate or available in your locations. We may
                limit the availability of our Platforms or any service or
                product described on our Platforms to any person or geographic
                area at any time. If you choose to access our Platforms from
                your location, you do so at your own risk.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>Prohibited Use</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                You must not access or use our Platforms for any purpose other
                than that for which we make our Platforms available. You must
                also not use our Platforms in connection with any commercial
                endeavours, except if we have expressed agreement to allow you
                to do so in a contract with us.
              </ListItem>
              <ListItem>
                Specifically, you must not, without our written permission, do
                any of the following:
              </ListItem>
              <ListItem>
                Make any unauthorised use of our Platforms, such as collecting
                usernames and/or email addresses of users by electronic or other
                means for the purpose of sending unsolicited email, or creating
                user accounts by automated means or under false pretenses;
              </ListItem>
              <ListItem>
                Circumvent, disable, or otherwise interfere with
                security-related features of our Platforms, including features
                that prevent or restrict the use or copying of any Content or
                enforce limitations on the use of our Platforms and/or the
                content contained therein;
              </ListItem>
              <ListItem>
                Interfere with, disrupt, or create an undue burden on our
                Platforms or the networks or services connected to our
                Platforms;
              </ListItem>
              <ListItem>
                Attempt to bypass any measures of the Site designed to prevent
                or restrict access to our Platforms, or any portion of our
                Platforms;
              </ListItem>
              <ListItem>
                Systematically retrieve data or other content from our Platforms
                to create or compile, directly or indirectly, a collection,
                compilation, database, or directory;
              </ListItem>
              <ListItem>
                Trick, defraud, or mislead us or other users, especially in any
                attempt to learn sensitive account information such as users’
                account details and passwords;
              </ListItem>
              <ListItem>
                Attempt to impersonate another user or person or use the
                username of another user;
              </ListItem>
              <ListItem>
                Use any information obtained from our Platforms to harass,
                abuse, or harm another person;
              </ListItem>
              <ListItem>
                Use a buying agent or purchasing agent to make purchases on our
                Platforms;
              </ListItem>
              <ListItem>
                Engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools;
              </ListItem>
              <ListItem>
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojans, or other material, including excessive use of
                capital letters and spamming (continuous posting of repetitive
                text), that interferes with any party’s uninterrupted use and
                enjoyment of our Platforms or modifies, impairs, disrupts,
                alters, or interferes with the use, features, functions,
                operation, or maintenance of our Platforms;
              </ListItem>
              <ListItem>
                Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
                cookies, or other similar devices (sometimes referred to as
                “spyware” or “passive collection mechanisms” or “pcms”);
              </ListItem>
              <ListItem>
                Except as may be the result of standard search engines or
                Internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                our Platforms, or using or launching any unauthorised script or
                other software;
              </ListItem>
              <ListItem>
                Decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of our
                Platforms;
              </ListItem>
              <ListItem>
                Delete the copyright or other proprietary rights notice from any
                content in our Platforms;
              </ListItem>
              <ListItem>
                Copy or adapt our Platforms’ software, including but not limited
                to Flash, PHP, HTML, JavaScript or other code;
              </ListItem>
              <ListItem>
                Use our Platforms as part of any effort to compete with us or
                otherwise use our Platforms and/or its content for any
                revenue-generating endeavour or commercial enterprise;
              </ListItem>
              <ListItem>
                Make improper use of our support services or submit false
                reports of abuse or misconduct;
              </ListItem>
              <ListItem>
                Harass, intimidate, or threaten any of our employees or agents
                engaged in providing any portion of our Platforms to you;
              </ListItem>
              <ListItem>
                Use our Platforms to advertise or offer to sell unauthorised
                goods and services;
              </ListItem>
              <ListItem>
                Engage in unauthorised framing of or linking to our Platforms;
              </ListItem>
              <ListItem>
                Disparage, tarnish, or otherwise harm, in our opinion, us and/or
                our Platforms; or
              </ListItem>
              <ListItem>
                Use our Platforms in a manner inconsistent with any applicable
                laws or regulations.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              <ListItem>Intellectual Property</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                Unless otherwise stated, we are the owner or the licensee of all
                intellectual property rights in our Platforms and in the
                material published on it, including but not limited to source
                codes, databases, functionalities, software, website designs,
                audio, video, texts, photographs, and graphics. Those works are
                protected by legislation such as copyright, designs and
                trademark legislations and under international treaties and
                national laws worldwide.
              </ListItem>
              <ListItem>
                In general, all trademarks, logos and service marks
                (collectively, trademarks) that appear on our Platforms are
                registered, unregistered or otherwise protected by our
                trademarks or are licensed for use by us by third-parties. Other
                trademarks are proprietary marks and are registered to their
                respective owners. Nothing contained on our Platforms should be
                construed as granting, by implication or otherwise, any license
                or right to use any trademark without our prior written
                permission or that of such third-party who owns the trademark.
                Misuse of any trademark displayed on our Platforms, or any other
                content on our Platforms, except as provided herein, is strictly
                prohibited.
              </ListItem>
              <ListItem>
                All content on our Platforms are also either copyright or are
                licensed for use by us. All rights are reserved.
              </ListItem>
              <ListItem>
                Our status (and that of any identified contributors) as the
                authors of content on our Platforms must always be acknowledged.
              </ListItem>
              <ListItem>
                You are not authorised to, sell, reproduce, distribute,
                communicate, modify, display, publicly perform, report or
                otherwise prepare derivative or second-hand works based on or
                use any part of the content on our Platforms in any way for any
                public or commercial purposes unless otherwise stated in these
                Terms of Use without obtaining a licence to do so from us or our
                licensors. The content on our Platforms may also not be
                displayed or communicated by you on any other platform, in a
                networked computer environment or on any other digital platform
                for any purpose whatsoever without a licence to do so from us or
                our licensors.
              </ListItem>
              <ListItem>
                You are also not authorised to systematically retrieve data or
                other content from our Platforms to create or compile, directly
                or indirectly, a collection, compilation, database, or directory
                without our written permission.
              </ListItem>
              <ListItem>
                In the event of breach of any of these Terms of Use, your
                permission to use our Platforms will automatically terminate and
                any copies made of any content on our Platforms must be
                immediately destroyed. Any unauthorised use of the content on
                our Platforms may infringe copyright laws, trademark laws, the
                laws of privacy and publicity, and communications regulations
                and statutes.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>No Reliance on Information</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                The content on our Platforms is provided for general information
                only. It is not intended to amount to advice on which you should
                rely. You must obtain professional or specialist advice before
                taking, or refraining from, any action on the basis of the
                content on our Platforms.
              </ListItem>
              <ListItem>
                Although we make reasonable efforts to update the information on
                our Platforms, we make no representations, warranties or
                guarantees, whether expressed or implied, that the content on
                our Platforms is accurate, complete or up-to-date.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>Limitation of our Liability</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                To the extent permitted by law, we exclude all conditions,
                warranties, representations or other terms which may apply to
                our Platforms or any content on it, whether express or implied.
              </ListItem>
              <ListItem>
                We try to ensure that the information provided on our Platforms
                is accurate and complete. However, we do not warrant or
                represent that the content in our Platforms is accurate,
                error-free or reliable or that use of our Platforms will not
                infringe rights of third-parties.
              </ListItem>
              <ListItem>
                We will not be liable to any user for any loss or damage,
                whether in contract, tort (including negligence), breach of
                statutory duty, or otherwise, even if foreseeable, arising under
                or in connection with use of, or inability to use, our Platforms
                or use of or reliance on any content displayed on our Platforms.
              </ListItem>
              <ListItem>
                If you are a business user, please note that in particular, we
                will not be liable for:
              </ListItem>
              <ListItem>loss of profits, sales, business, or revenue;</ListItem>
              <ListItem>business interruption;</ListItem>
              <ListItem>loss of anticipated savings;</ListItem>
              <ListItem>
                loss of business opportunity, goodwill or reputation; or any
                indirect or consequential loss or damage.
              </ListItem>
              <ListItem>
                If you are a consumer user, please note that we only provide our
                Platforms for domestic and private use. You agree not to use our
                Platforms for any commercial or business purposes, and we have
                no liability to you for any loss of profit, loss of business,
                business interruption, or loss of business opportunity.
              </ListItem>
              <ListItem>
                You are solely responsible for paying any and all sales, use,
                value-added and other taxes, duties, and assessments (except
                taxes on our net income) now or hereafter claimed or imposed by
                any authority (collectively, Taxes) associated with your use of
                our Platforms. Except for income taxes levied on us, you will
                pay or reimburse us for all national, federal, state, local, or
                other Taxes and assessments of any jurisdiction, as are now or
                hereafter may be imposed under the authority of any national,
                state, local or any other taxing jurisdiction and you shall not
                be entitled to deduct the amount of any such Taxes from payments
                made to us pursuant to these Terms of Use.
              </ListItem>
              <ListItem>
                We will not be liable for any loss or damage caused by a virus,
                distributed denial-of-service attack, or other technologically
                harmful material that may infect your computer equipment,
                computer programs, data or other proprietary material due to
                your use of our Platforms or to your downloading of any content
                on it, or on any website linked to it.
              </ListItem>
              <ListItem>
                We will maintain certain data that you transmit to our Platforms
                for the purpose of managing the performance of our Platforms, as
                well as data relating to your use of our Platforms. Although we
                perform regular routine backups of data, you are solely
                responsible for all data that you transmit or which relate to
                any activity by you on our Platforms. You agree that we shall
                have no liability to you for any loss or corruption of any such
                data, and you hereby waive any right of action against us
                arising from any such loss or corruption of such data.
              </ListItem>
              <ListItem>
                We assume no responsibility for the content of websites linked
                on our Platforms. Such links should not be interpreted as
                endorsement by us of those linked websites. We will not be
                liable for any loss or damage that may arise from your use of
                them.
              </ListItem>
              <ListItem>
                You agree and acknowledge that we have made our Platforms
                available to you and entered into these Terms of Use in reliance
                on the terms therein, including these limitations of liability,
                which reflect a reasonable and fair allocation of risk between
                the parties and form an essential basis of the bargain between
                us. We would not be able to provide our Platforms to you without
                these limitations.
              </ListItem>
              <ListItem>
                You agree to defend, indemnify, and hold us harmless, including
                our subsidiaries, affiliates, and all of our respective
                officers, agents, partners, and employees, from and against any
                loss, damage, liability, claim, or demand, including reasonable
                attorneys’ fees and expenses, made by a third-party due to or
                arising out of the (1) use of our Platforms; (2) breach of these
                Terms of Use; (3) breach of your representations and warranties
                set forth in these Terms of Use; (4) your violation of the
                rights of a third-party, including but not limited to
                intellectual property rights; or (5) any overt harmful act from
                any other person’s use of our Platforms with whom you connected.
                Notwithstanding the foregoing, we reserve the right to assume
                the exclusive defence and control of any matter for which you
                are required to indemnify us at your expense, and you agree to
                cooperate with our defence of such claims. We will use
                reasonable efforts to notify you of any such claim, action or
                proceeding which is subject to this indemnification upon
                becoming aware of it.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              <ListItem>Assumption of Risks</ListItem>
            </strong>{" "}
            <ListItemno>
              You accept and acknowledge each of the following:
            </ListItemno>
            <AlphaList>
              <ListItem>
                The prices of blockchain assets are extremely volatile.
                Fluctuations in the prices of other digital assets could
                materially and adversely affect the value of your $BEST tokens,
                which may also be subject to significant price volatility. We
                cannot guarantee that you will not lose money.
              </ListItem>
              <ListItem>
                You are solely responsible for determining whether and which
                Taxes apply to your transactions.
              </ListItem>
              <ListItem>
                There are risks associated with using internet-based and digital
                assets, including, but not limited to, the risk of hardware,
                software and Internet connection issues, introduction of viruses
                and malicious software, unauthorised access by third-parties to
                information stored in your digital wallet etc. You accept and
                acknowledge that we will not be responsible for any
                communication failures, disruptions, errors, distortions or
                delays you may experience when using the Ethereum network.
              </ListItem>
              <ListItem>
                A lack of use or public interest in the creation and development
                of distributed ecosystems could negatively impact the
                development of the Best Web3 ecosystem, and therefore the
                potential utility.
              </ListItem>
              <ListItem>
                The regulatory regime governing blockchain technologies,
                cryptocurrencies, and tokens is uncertain, and new regulations
                or policies may materially adversely affect the development of
                the Best Card ecosystem and the potential utility or value of
                the $BEST token.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>Uploading Content to our Platforms</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                Whenever you make use of a feature that allows you to upload any
                content, questions, comments, suggestions, ideas, feedback or
                other information regarding our Platforms (Submissions) to our
                Platforms, or to make contact with other users of our Platforms,
                you must comply with the content standards set out in these
                Terms of Use. You warrant that any such Submissions complies
                with those standards, and you will be liable to us and indemnify
                us for any breach of that warranty.
              </ListItem>
              <ListItem>
                Any content you upload to our Platforms will be considered
                non-confidential and non-proprietary. You retain all of your
                ownership rights in your content, but you are required to grant
                us and other users of the Site a limited licence to use, store
                and copy that content and to distribute and make it available to
                third-parties. We also have the right to disclose your identity
                to any third-party who is claiming that any content posted or
                uploaded by you to our Platforms constitutes a violation of
                their intellectual property rights, or of their right to
                privacy.
              </ListItem>
              <ListItem>
                We will not be responsible, or liable to any third-party, for
                the content or accuracy of any content posted by you or any
                other user of our Platforms.
              </ListItem>
              <ListItem>
                We have the right to remove any posting you make on our
                Platforms if, in our opinion, your post does not comply with the
                content standards set out in these Terms of Use.
              </ListItem>
              <ListItem>
                The views expressed by other users on our Platforms do not
                represent our views or values.
              </ListItem>
              <ListItem>
                You are solely responsible for securing and backing up your
                content.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              <ListItem>Viruses</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                We do not guarantee that our Platforms will be secure or free
                from bugs or viruses.
              </ListItem>
              <ListItem>
                You are responsible for configuring your device, information
                technology, computer programmes and platform in order to access
                our Platforms. You should use your own virus protection
                software.
              </ListItem>
              <ListItem>
                You must not misuse our Platforms by knowingly introducing
                viruses, trojans, worms, logic bombs or other material which is
                malicious or technologically harmful. You must not attempt to
                gain unauthorised access to our Platforms, the servers on which
                our Platforms are stored or any server, computer or database
                connected to our Platforms. You must not attack our Platforms
                via a denial-of-service attack or a distributed denial-of
                service attack. By breaching this provision, you may be
                committing a criminal offence. We will report any such breach to
                the relevant law enforcement authorities and we will cooperate
                with those authorities by disclosing your identity to them. In
                the event of such a breach, your right to use our Platforms will
                cease immediately.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>Linking to our Platforms</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                You may link to our Platforms, provided you do so in a way that
                is fair and legal and does not damage our reputation or take
                advantage of it.
              </ListItem>
              <ListItem>
                You must not establish a link in such a way as to suggest any
                form of association, approval or endorsement on our part where
                none exists.
              </ListItem>
              <ListItem>
                Our site must not be framed on any other site.
              </ListItem>
              <ListItem>
                We reserve the right to withdraw linking permission without
                notice.
              </ListItem>
              <ListItem>
                The website in which you are linking must comply in all respects
                with the content standards set out in these Terms of Use.
              </ListItem>
              <ListItem>
                If you wish to make any use of content on our Platforms other
                than that set out above, please contact contact@bestweb3.com .
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>
                Third-Party Links and Resources in our Platforms
              </ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                third-parties, these links are provided for your information
                only. We have no control over the contents of those sites or
                resources.
              </ListItem>
              <ListItem>
                The inclusion of any link to such third-party sites does not
                imply endorsement by us of those sites. We have not reviewed all
                of the content contained in the linked sites and we are not
                responsible for the content or accuracy of any off-site pages or
                any other sites linked to our Platforms. If you choose to click
                through any link to off-site pages or third-party sites then
                this is and deemed to be done at your own risk.
              </ListItem>{" "}
              <ListItem>
                We may also allow third-parties to display their advertisements
                and other information on the Website. We simply provide the
                space to place such advertisements, and we have no other
                relationship with these third-party advertisers. The inclusion
                of advertisements does not imply endorsement by us of the
                subject matter of the advertisements. We have not reviewed the
                contents of the advertisements and we are not responsible for
                the content or accuracy of any such advertisements. If you
                choose to click the advertisement link to off-site pages or
                third-party sites then this is and deemed to be done at your own
                risk.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>Termination and Suspension</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                We may terminate, block or suspend your access to and use of our
                Platforms if we reasonably believe that you have violated or
                acted inconsistently with the letter or spirit of these Terms of
                Use, or violated our rights, our affiliated companies or any
                third-party, with or without notice to you. You agree that we
                may modify or discontinue providing access to our Platforms,
                with or without notice to you. You agree that we will not be
                liable to you or any third-party as a result of such
                modification or discontinuation. The provisions entitled
                “Limitation of our Liability” and “General Provisions” will
                survive termination of these Terms of Use.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              <ListItem>General Provisions</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                We make no representation that the content on our Platforms is
                appropriate or available for use in every country of the world.
                You are responsible for compliance with applicable local laws,
                keeping in mind that access to our Platforms may not be legal by
                certain persons or in certain countries.
              </ListItem>
              <ListItem>
                If any provision, or part thereof, of these Terms of Use is
                found to be illegal, invalid or unenforceable, that provision or
                part-provision shall be deemed not to form part of these Terms
                of Use, and the legality, validity or enforceability of the
                remainder of the provisions of these Terms of Use shall not be
                affected, unless otherwise required by operation of applicable
                law.
              </ListItem>
              <ListItem>
                These Terms of Use constitute the entire agreement between you
                and us in relation to the use of our Platforms, and replace and
                extinguish all prior agreements, draft agreements, arrangements,
                undertakings, or collateral contracts of any nature made by the
                parties, whether oral or written, in relation to such subject
                matter.
              </ListItem>
              <ListItem>
                Any waiver by us of a breach of any provision of these Terms of
                Use will not operate to be interpreted as a waiver of any other
                or subsequent breach.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>Applicable Law and Jurisdiction</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                Please note that these Terms of Use, its subject matter and its
                formation (and any non-contractual disputes or claims), are
                governed by the laws of the Marshall Islands. You and we both
                agree that the courts of the MArshall Islands will have
                exclusive jurisdiction.
              </ListItem>
            </AlphaList>{" "}
            <strong>
              {" "}
              <ListItem>Partnerships</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                Our Platforms may form official partnerships with third parties.
                All official partnerships will be announced on our Platforms,
                and that of the third party.
              </ListItem>
              <ListItem>
                Any third party who solely announces a partnership may be false.
              </ListItem>
            </AlphaList>
            <strong>
              {" "}
              <ListItem>Contact Us</ListItem>
            </strong>{" "}
            <AlphaList>
              <ListItem>
                If you have any questions or comments about these Terms of Use,
                or matters generally, please contact us at contact@bestweb3.com.
                You can also use this email address if you wish to request a
                copy of the personal data we hold about you or to request your
                account to be deleted.
              </ListItem>
            </AlphaList>
          </NumberedList>
          <ParaHead>Thank you for using our Platforms.</ParaHead>
          <ParaHead>Last Updated: October 25th 2023</ParaHead>
        </ParaContainer>
      </div>
      <Footer />
    </>
  );
}
export default TermsAndConditions;
